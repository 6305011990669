import {
    elementOpen,
    elementClose,
    skip,
    text
} from 'incremental-dom';

import { modulePage, linkClick } from '@appzuka/rxnano';

import './serviceBlocks.scss';
import placeholderSVG from './placeholder.svg';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

const serviceBlockLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "serviceblockimages" */ './serviceBlockContent');
};

const placeholder = () => { placeholderSVG('service-block');};

// TODO: Make this load reduced images first on a slow network.
// TODO: Better to split the files into separate modules?

const webdesign = (pco, delay = 0) =>
    modulePage(pco, 'webdesign', 'serviceblockimages', serviceBlockLoader, {loaderOptions: {delay: delay, placeholder: placeholder, class: 'service-svg'}});

const mobileapp = (pco, delay = 0) =>
    modulePage(pco, 'mobileapp', 'serviceblockimages', serviceBlockLoader, {loaderOptions: {delay: delay, placeholder: placeholder, class: 'service-svg'}});

const consulting = (pco, delay = 0) =>
    modulePage(pco, 'consulting', 'serviceblockimages', serviceBlockLoader, {loaderOptions: {delay: delay, placeholder: placeholder, class: 'service-svg'}});

const singleBlock = (link, icon, title, desc) => {
    <render>
        <a href={link} onclick = {(event) => linkClick(event, link)} class='service-three-block service-icon'>
            {icon}
        </a>
        <a href={link} onclick = {(event) => linkClick(event, link)} class='service-three-block service-title'>
            <h2>{title}</h2>
        </a>
        <a href={link} onclick = {(event) => linkClick(event, link)} class='service-three-block service-desc'>
            <p>{desc}</p>
        </a>
    </render>
}

// TODO: Add 'Learn Why...' link to each box
const serviceBlocks = (pco, delay = 0) => {
    <render>
        <div class='service-block-wrapper'>
            {() => singleBlock('/services/web', () => webdesign(pco), 'Websites', 'Sites built from large frameworks don\'t perform on today\'s mobile web.  Learn how we build websites from hand and why this is essential for your site.')}
            {() => singleBlock('/services/app', () => mobileapp(pco), 'Mobile Apps', 'Give your business the edge with your own mobile app. We can build native iOS and Android apps using the same technology as web development.')}
            {() => singleBlock('/services/consulting', () => consulting(pco), 'Consulting', 'appZuka cover web, mobile, search, deployment and design.  We provide a single contact point for all your requirements.')}
        </div>
    </render>
}

export {
    serviceBlocks
};
