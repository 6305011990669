import {
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import { linkClick } from '@appzuka/rxnano';

import { info, iceberg, fastpc, brain, blocks } from './webImages';
// NICE: Make the background for the blocks extend to the edge of the page

import './web-design.scss';

// import { markup, clickLink } from 'components/utils';
// import icebergSVG from './iceberg.svg';
// import { webDesignBlocks, webDesignBrain, webDesignFastPC, webDesignInfo } from 'site/modules/webDesign/webDesign';

// TODO: Convert to tsx
const spanReplace = (line) => {
    if (line.indexOf('[') > 0) {
        let linestart = line.slice(0, line.indexOf('['));
        let lineend = line.slice(line.indexOf(']')+1);
        let spanText = line.slice(line.indexOf('[')+1, line.indexOf(']'));
        if (spanText.indexOf('|') > -1) {
            let spanLink = spanText.slice(spanText.indexOf('|')+1);
            let spanContent = spanText.slice(0, spanText.indexOf('|'));
            text(linestart);
            elementOpen('a', null, ['href', spanLink, 'target', '_blank', 'rel', 'noopener noreferrer']);
                text(spanContent);
            elementClose('a');
            spanReplace(lineend);
        } else {
            let spanContent = spanText.slice(spanText.indexOf(':')+1);
            let spanClass = spanText.slice(0, spanText.indexOf(':'));
            text(linestart);
            elementOpen('span', null, ['class', spanClass]);
                text(spanContent);
            elementClose('span');
            spanReplace(lineend);
        }

    } else {
        text(line);
    }
};

const markup = (content) => {
    let inList = false;
    content.split('\n').map((l) => {
        const t = l.trim();

        if (t.length > 0) {
            let element = t.slice(0, t.indexOf(' '));
            let elementClass = null;
            if (element.indexOf('[') > 0) {
                element = t.slice(0, t.indexOf('['));
                elementClass = t.slice(t.indexOf('[') + 1, t.indexOf(']'));
            }
            const line = t.slice(t.indexOf(' '));
            if ((element === 'li') && (inList === false)) {
                elementOpen('ul');
                inList = true;
            }
            if ((element !== 'li') && (inList === true)) {
                elementClose('ul');
                inList = false;
            }

            elementOpen(element, null, elementClass !== null ? ['class', elementClass] : null);
                spanReplace(line);
            elementClose(element);
        }
    });
    if (inList) {
        elementClose('ul');
    }
};


// Accents summer from adobe color
const themeColor = ['#C64C54', '#FABF50', '#79B6B8', '#994D7E', '#F79781'];

const webDesignPageContent = (pco) => {
        
    elementOpen('div', 'page-web-design-blocks', null, 'class', 'page-standard full-width no-bottom-padding');

        elementOpen('div', null, null, 'class', 'flex-row');

            elementOpen('div', null, null, 'id', 'speed', 'class', 'flex-row-left flex-row-content', 'style', `background-color: ${themeColor[3]}; color: white`);
                markup(`h2 Speed Matters to Your Users
                p Research by Google shows users put the highest importance on page load speed and the lowest importance on how attractive the site is.
                p 53% of all site visits are abandoned if page load takes more than 3 seconds even on low powered mobile devices.
                p[fact] The average user device costs less than USD200
                p If a site takes  more than 1 second to become interactive, users lose attention, and their perception of completing the page task is broken.
                p[fact] 1MB takes a minimum of 5 seconds to download on a typical 3G connection.
                p Despite this, 19 seconds is the average time a mobile web page takes to load on a 3G connection.  Read on to learn the reasons for slow loading sites and why appZuka sites are different.`);
            elementClose('div');
            elementOpen('div', null, null, 'class', 'flex-row-right flex-row-image', 'style', 'background-color: white');
                info(pco);
                markup(`h2 What is Important to Users`);
            elementClose('div');

        elementClose('div');

        elementOpen('div', null, null, 'class', 'flex-row flex-row-reverse');

            elementOpen('div', null, null, 'id', 'heavy', 'class', 'flex-row-left flex-row-content', 'style', `background-color: ${themeColor[2]}; color: white`);
                markup(`h2 Heavy Frameworks
                p The vast majority of websites are built using frameworks.  These make building the site easy for the designer but start by adding a huge amount of code to your site which all needs to be downloaded and executed before the page can be shown.
                p Then the designer adds modules for each effect on the site such as menus, slideshows, forms.  Most designers don't realise the impact of each module they add.
                p Before your first page is added the site has a huge amount of code which all has to be downloaded and processed for every page.  Your content is like the tip of an iceberg with the vast framework beneath.
                p[fact] Users care about the content, not the design.
                p Here at appZuka, we don't use frameworks.  The entire site is hand-coded so not a single byte is allowed onto your site unless it adds value.`);
            elementClose('div');
            elementOpen('div', null, null, 'class', 'flex-row-right flex-row-image small-border', 'style', 'background-color: white');
                iceberg(pco);
            elementClose('div');

        elementClose('div');

        elementOpen('div', null, null, 'class', 'flex-row');

            elementOpen('div', null, null, 'class', 'flex-row-left flex-row-content', 'style', `background-color: ${themeColor[0]}; color: white`);
            markup(`h2 Mobile First Mindset
                p Web designers work with very powerful desktop computers with lots of RAM, fast CPUs and super-fast broadband.  They don't see the impact on mobile users of the modules they are adding to your web pages.
                h4 Responsive Design is not Enough
                p You can simulate a small screen just by making your browser window smaller.  Simulating a low RAM, low CPU device on a mobile network requires more thought and most web designers today are not doing this.
                h4 It is not just about download speeds
                p Mobile devices have low RAM and CPU's designed to save battery life. If your page uses too much RAM the page will not just be slow to load - it will be killed by the device.`);
            elementClose('div');

            elementOpen('div', null, null, 'class', 'flex-row-right flex-row-image');
                fastpc(pco);
            elementClose('div');

        elementClose('div');

        elementOpen('div', null, null, 'class', 'flex-row flex-row-reverse');

            elementOpen('div', null, null, 'class', 'flex-row-left flex-row-content', 'style', `background-color: ${themeColor[1]}; color: white`);
            markup(`h2 Be Smart About Images
                p Web designers today have access to high-resolution images from stock photo libraries and pull in high-resolution images without considering the image on the page load time.  The images on a typical site are responsible for the majority of the download time.
                p You can still have great images.  We ensure the images are optimised so only the data necessary to show the image on the user's device is downloaded.
                p The page is displayed and becomes interactive as soon as the content is downloaded.  Heavy images are replaced by placeholders and the rich image displays smoothly when it downloads to avoid a jarring user experience.  Being smart gives the best of both worlds - a super fast site and rich images.
                p[fact] Don't forget other assets
                p The same applies to fonts.  Rich font libraries allow designers to use many styles, sizes and weights of a font which will delay page loading.  We only use lightweight fonts for the initial page load.`);
            elementClose('div');

            elementOpen('div', null, null, 'class', 'flex-row-right flex-row-image');
                brain(pco);
            elementClose('div');

        elementClose('div');

        elementOpen('div', null, null, 'class', 'flex-row');

            elementOpen('div', null, null, 'id', 'clever', 'class', 'flex-row-left flex-row-content', 'style', `background-color: ${themeColor[4]}; color: white`);
            markup(`h2 Clever Loading
                p You can have both a fast loading website and also a large site with rich content.
                p Through hand coding, we can load only the content the user needs to see for the page they are viewing.  Rich content is loaded after the initial page display.
                p The initial content includes placeholder images so the user does not perceive that the page loads slowly.  They see a full page quickly with progressive, smooth enhancement.  Users on desktop or powerful mobile devices see the rich page on the first load.
                p Contact us to discuss your website.`);
            elementClose('div');

            elementOpen('div', null, null, 'class', 'flex-row-right flex-row-image');
                blocks(pco);
            elementClose('div');

        elementClose('div');

        elementOpen('p');
            text('If you are interested in getting your own fast website click here to learn how: ');
            elementOpen('a', null, null, 'href', '/services/web/nextsteps',
            'onclick', (event) => {
                linkClick(event, '/services/web/nextsteps');
            });
                text('Web Design - Next Steps');
            elementClose('a');
        elementClose('p');

    elementClose('div');


        

}

const webDesignNextStepsContent = (pco) => {
    elementOpen('div', 'page-services-topxx', null, 'id', 'page-services-topxx', 'class', 'page-standard');
        markup(`h2 How Much Will it Cost?
        p The short answer is "It depends on what you want". But here is an example of a basic site:
        
        p Modern Site Layout with
        li Header with Single Level Menu
        li Footer with Contact Details
        li All Pages with Responsive Banner Images
        li Contact Page with Map
        li Selection  of pages and page components from portfolio with changes to content, colour & font
        li Sitemap
        li Submission to Google
        li Integration of Google Analytics
        li https for security
        li Up 5 Stock Library Images
        li Integration of up to 2 google Webfonts
        li Optimised for speed with Lighthouse score > 90

        p Take a look at some basic sites in our portfolio for examples:

        li Fifthsense
        li Refuge Recovery
        li Procom
        li appZuka.io

        h2 Cost Guideline
        
        li Design £500
        li Hosting & monitoring including 2 changes of content on existing pages per month £100 / month
        li Additional Pages: £100 per page

        p If we can reduce the work by re-using design from existing sites the cost could be even less.  Whatever your budget, please get in contact and we will help you to specify a great looking site which fits your budget.

        h2 Custom Content

        p Some examples of customised content and functionality are:

        li Customised page layout
        li Customer Upload Form
        li Admin Login
        li Customer Login & Database
        li Page to allow you to upload your own content

        p The cost of these will depend on the work required.  Please get in touch and we will be pleased to discuss your requirement.
        
        h2 What Will I need to Provide
        p The Content for your site in any form (Word, Powerpoint).  Split your content into the pages and we will work out how to place this on the page and make it work for all screen sizes.

        h2 How Long Does it Take?
        As a guide, 2 to 4 weeks for a basic site.  This largely depends on how quickly you can provide the content and how quickly you are able to give feedback to the draft site.  If you can provide content quickly the site could be up and running in a few days.
        
        h2 Get Started`);

        elementOpen('p');
            text('Please get in touch for a discussion without obligation: ');
            elementOpen('a', `contact-link`, null, 'href', 'mailto:info@appzuka.io');
                text('info@appzuka.io');
            elementClose('a');
        elementClose('p');
        
    elementClose('div');
}

export {
    webDesignPageContent,
    webDesignNextStepsContent
};

