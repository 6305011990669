import {
  initApp,
  packageVersion as rxnanoBasePackageversion,
  rxnanoComponent,
  triggerPageChange, preloadMaps
} from '@appzuka/rxnano';

import { packageVersion as rxnanoContactformPackageversion } from '@appzuka/rxnano-contactform';
import { pageList } from './site/pages/pages';

import { TestString } from '@appzuka/ref';

import {
  HeadMenuContent, DropMenuContent, SideMenuContent, HeaderSiteLogo,
  FooterContacts, FooterAddress, FooterSiteLogo, SiteData
} from './site/siteContent';

import './site/fonts.scss';

const CommitInfo = require('virtual/commitinfo.js');

const prerendering = window.location.search.match(/prerender/) ? true : false;

const pageChangeObservable = initApp(pageList, SiteData);

// div already present in html
// only inserted once
// NICE: Could be delayed until after main render
if (!prerendering) {
  rxnanoComponent.insertFooter(FooterContacts, FooterAddress, FooterSiteLogo, CommitInfo.commit, CommitInfo.builddate,
    ['appzuka/' + CommitInfo.commit, rxnanoBasePackageversion, rxnanoContactformPackageversion],
    {
      policies: [
        {label: 'Website Terms', link: '/policies/terms'},
        {label: 'Privacy Policy', link: '/policies/privacy'},
      ],
      copyrightYear: '2024'
    });
};

const wide = document.body.clientWidth > 1023;
const DM = wide ? rxnanoComponent.DropMenu(DropMenuContent) : rxnanoComponent.SideMenu(SideMenuContent);

// NICE: Could set up drop menu after main render.  Would need to show hamburger icon during first render

rxnanoComponent.HeadMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);
triggerPageChange(null);

// Preload the maps api if required on the contact page
if (!prerendering) {
  rxnanoComponent.ScrollMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM); // Once installed, the scroll menu handles itself
  preloadMaps(5000);
};

import './site/pages/overrides.scss'; // Overrides last
