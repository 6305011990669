import {
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

const spanReplace = (line) => {
    if (line.indexOf('[') > 0) {
        let linestart = line.slice(0, line.indexOf('['));
        let lineend = line.slice(line.indexOf(']')+1);
        let spanText = line.slice(line.indexOf('[')+1, line.indexOf(']'));
        if (spanText.indexOf('|') > -1) {
            let spanLink = spanText.slice(spanText.indexOf('|')+1);
            let spanContent = spanText.slice(0, spanText.indexOf('|'));
            text(linestart);
            elementOpen('a', null, ['href', spanLink, 'target', '_blank', 'rel', 'noopener noreferrer']);
                text(spanContent);
            elementClose('a');
            spanReplace(lineend);
        } else {
            let spanContent = spanText.slice(spanText.indexOf(':')+1);
            let spanClass = spanText.slice(0, spanText.indexOf(':'));
            text(linestart);
            elementOpen('span', null, ['class', spanClass]);
                text(spanContent);
            elementClose('span');
            spanReplace(lineend);
        }

    } else {
        text(line);
    }
};

// TODO - allow raw html
const markup = (content) => {
    let inList = false;
    content.split('\n').map((l) => {
        const t = l.trim();

        if (t.length > 0) {
            let element = t.slice(0, t.indexOf(' '));
            let elementClass = null;
            if (element.indexOf('[') > 0) {
                element = t.slice(0, t.indexOf('['));
                elementClass = t.slice(t.indexOf('[') + 1, t.indexOf(']'));
            }
            const line = t.slice(t.indexOf(' '));
            if ((element === 'li') && (inList === false)) {
                elementOpen('ul');
                inList = true;
            }
            if ((element !== 'li') && (inList === true)) {
                elementClose('ul');
                inList = false;
            }
            elementOpen(element, null, elementClass !== null ? ['class', elementClass] : null);
                spanReplace(line);
            elementClose(element);
        }
    });
    if (inList) {
        elementClose('ul');
    }
};

export {
    markup
}
