import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';


import { BehaviorSubject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import './loginform.scss';

const formData = new BehaviorSubject({password: '', verify: ''});

const passwordSubject = new BehaviorSubject(undefined);
const verifySubject = new BehaviorSubject(undefined);

let bothFilled = false;

const validObserver = combineLatest(passwordSubject, verifySubject).pipe(
    // tap(v => console.log(v))
).subscribe(v => {
    // console.log(v);
    if ((v[0] === undefined) || (v[1] === undefined)) {
        // console.log('1 input not given');
    } else {
        const el = document.getElementById('passwordpasswordinput') as HTMLDivElement;
        const labelel = document.getElementById('password-label') as HTMLDivElement;
        if (v[0] === false) {
            el.style.backgroundColor = '';
            // subject.next(true);
            labelel.classList.remove('input-invalid');
        } else {
            el.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
            // subject.next(false);
            labelel.classList.add('input-invalid');
        }
    }
});

const passwordInput = (pageChangeObservable, subject, label, dataKey, options) => {

    const errorMsg = (dataKey === 'verify')?'New password and verification are not the same':'Password must be valid';

    elementOpen('div', null, ['class', 'input-element']);
        const el = elementVoid('input', `${dataKey}-input`, ['id', `${dataKey}passwordinput`, 'type', 'password', 'autofocus', (dataKey === 'old')]) as HTMLInputElement;
        const labelel = elementOpen('p', `${dataKey}-label`, ['class', 'input-label', 'id', `${dataKey}-label`]);
            text(label)
            elementOpen('span', null);
                text(errorMsg);
            elementClose('span');
        elementClose('p');
    elementClose('div');

    el.style.borderBottomColor=options.highlight;

    const source = fromEvent(el, 'input');
    const inputBlur = fromEvent(el, 'blur');

    const validInput = () => {
        subject.next(el.value.length > 6);
        // if (dataKey === 'verify')
        //     return el.value === formData.getValue().password;
        // else if (dataKey === 'password') {
        //     if (formData.getValue().verify.length > 0) {
        //         const velpi = document.getElementById('verifypasswordinput') as HTMLElement;
        //         const vel = document.getElementById('verify-label') as HTMLElement;
        //         if (formData.getValue().verify !== el.value) {
        //             console.log('no equal')
        //             velpi.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
        //             vel.classList.add('input-invalid');
        //             return false;
        //         } else {
        //             velpi.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
        //             vel.classList.remove('input-invalid');
        //             return el.value.length > 6;
        //         }
        //     } else {
        //         return el.value.length > 6;
        //     }
        // }
        // else
        //     return el.value.length > 6;
    }

    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            subject.next(el.value.length > 6);
        }
        // () => {
        //     if (validInput()) {
        //         el.style.backgroundColor = '';
        //         subject.next(true);
        //         labelel.classList.remove('input-invalid');
        //     } else {
        //         el.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
        //         subject.next(false);
        //         labelel.classList.add('input-invalid');
        //     }
        // }
        // () => {
        //     if (el.value.length > 0) {
        //         labelel.classList.add("input-non-empty");
        //     } else {
        //         labelel.classList.remove("input-non-empty");
        //     }
        // }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        // () => {
        //     console.log('inputblur');
        //     if (validInput()) {
        //         el.style.backgroundColor = '';
        //         subject.next(true);
        //         labelel.classList.remove('input-invalid');
        //     } else {
        //         el.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
        //         subject.next(false);
        //         labelel.classList.add('input-invalid');
        //     }
        // }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
            el.value = data[dataKey];
            if (data[dataKey].length > 0) {
                labelel.classList.add("input-non-empty");
            } else {
                labelel.classList.remove("input-non-empty");
            }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            // if (validInput()) {
            //     el.style.backgroundColor = '';
            //     labelel.classList.remove('input-invalid');
            //     subject.next(true);
            // } else {
            //     subject.next(false); 
            // }
            // formData.next({...formData.value, [dataKey]: el.value})
        }
    );
}

const verifyInputs = () => {
    return {
        password: (pco, options) =>passwordInput(pco, passwordSubject, 'Password', 'password', options),
        verify: (pco, options) => passwordInput(pco, verifySubject, 'Verify Password', 'verify', options)
    }
}

export {
    verifyInputs
};

