import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';

import { PageContent, linkClick } from '@appzuka/rxnano';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SiteData } from 'site/siteContent';

import { changePasswordForm } from './changepassword';
import { forceChangePasswordForm } from './forcechangepassword';
import { forgotPasswordForm } from './forgotpassword';
import { loginForm } from './login';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import {
    AuthData,
    AuthSignout
} from './authImports';

import './loginform.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';

const logout = async (e, options) => {
    e.preventDefault();
    // console.log(e)
    AuthSignout();
    messageContent.next('User logged out');
}

const signOutForm = (pco, options) => {

    <render>
        <div id='current-user'><p>Current User: </p></div>
        <div class="login-form-wrapper">
        <input type='submit' onclick={(e)=>logout(e, options)} value="Logout"/>
        <input type='submit'  onclick={(e)=>authContent.next({action: 'changepassword'})} value="Change Password"/>
        <input type='submit'  onclick={(e)=>linkClick(e, '/account/errorlog')} value="Errorlog"/>
    </div>
    </render>

    AuthData().then(authData => {
        const userel = document.getElementById('current-user') as HTMLDivElement;
        if (authData.getValue().idToken === undefined) {
            patch(userel, () => {<render><p>No current user</p></render>}) // impossible?
        } else {
            patch(userel, () => {<render><p>User: {authData.getValue().idToken?.payload.email}</p></render>})
        };
    });
}

interface AuthContentData {
    action: string
    actiondata?: {
        cognitoUser?: CognitoUser
        email?: string
    }
}

const authContent = new BehaviorSubject({action: 'account' } as AuthContentData);
const messageContent = new BehaviorSubject('');

const loginContent = async (pco, options) => {

    <render>
        <div id="loginpage">
            <div id="account-panel">
                <h2>Client Login</h2>
                <div id='accountmessage'></div>
                <div id='logincontent'></div>
            </div>
            <div id="account-divider">
            </div>
            <div id="actions-panel">
                <h2>Actions</h2>
                <div id='actionscontent'></div>
            </div>
        </div>
    </render>

    authContent
    .pipe(
        takeUntil(pco), // Cancel if the page is changed
    )
    .subscribe(
        ({action, actiondata}) => {
            const accountel = document.getElementById('logincontent') as HTMLElement;
            const actionel = document.getElementById('actionscontent') as HTMLElement;

            if (action === 'login') {
                const {account, action} = loginForm(pco, options);
                patch(accountel, () => account());
                patch(actionel, () => action());
            } else if (action === 'logout') {
                patch(accountel, () => signOutForm(pco, options));
            } else if (action === 'changepassword') {
                patch(accountel, () => changePasswordForm(pco, options));
            } else if (action === 'forcechangepassword') {
                patch(accountel, () => forceChangePasswordForm(pco, options, actiondata!.cognitoUser, actiondata!.email));
            } else if (action === 'forgot') {
                patch(accountel, () => forgotPasswordForm(pco, options, actiondata!.email));
            } else if (action === 'account') {
                AuthData().then(authData => {
                    if (authData.getValue().idToken === undefined) {
                        authContent.next({action: 'login'});
                    } else {
                        authContent.next({action: 'logout'});
                    };
                })
                // patch(formel, () => changePasswordForm(pco, options, authContent));
            }
        }
    );

    messageContent
    .pipe(
        takeUntil(pco), // Cancel if the page is changed
    )
    .subscribe(
        (message) => {
            const msgel = document.getElementById('accountmessage') as HTMLElement;
            patch(msgel, () => <render><p>{message}</p></render>);
            // messageContent.next(''); //FIXME
        }
    );

    AuthData().then(authData => {
        authData
        .pipe(
            takeUntil(pco), // Cancel if the page is changed
        )
        .subscribe(
            (data) => {
                // const formel = document.getElementById('logincontent') as HTMLElement;
                if (data.idToken === undefined) {
                    authContent.next({action: 'login'})
                } else {
                    authContent.next({action: 'logout'})
                }
            }
        );
    });
}

const accountPages : PageContent = {
    content: [
        {
            pageLocation: 'account',
            image: ['banners/login', 'Client Login'],
            pageTitle: 'Account',
            index: false,
            content: (pco) => loginContent(pco, {highlight: SiteData.highlight})
        }
    ],
    subMenu: []
};

export {
    accountPages,
    messageContent,
    authContent
};

