import {
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import { modulePage, PageContent } from '@appzuka/rxnano';

import './landingContent.scss';

// const aboutLoader = () => {
//     return import(
//       /* webpackMode: "lazy",
//          webpackChunkName: "aboutcontent" */
//       'site/pages/about/aboutContent.tsx');
// };

const landingLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "landing" */ './landingContent');
};

const landingPages : PageContent = {
    content: [
        {
            pageLocation: 'landing',
            image: ['banners/landing', undefined],
            pageTitle: 'Landing Pages',
            content: (pco) => modulePage(pco, 'landing', 'landing', landingLoader, {pco: pco}),
        },
        // {
        //     pageLocation: 'pagespeed',
        //     image: ['banners/fastcar', 'About Us'],
        //     pageTitle: 'Landing Pages',
        //     content: (pco) => modulePage(pco, 'pagespeed', 'landing', landingLoader),
        // },
        {
            pageLocation: 'guildford-web-development',
            image: ['banners/guildford', undefined],
            pageTitle: 'Local Web Development',
            content: (pco) => modulePage(pco, 'localweb', 'landing', landingLoader, {pco: pco}),
        },


    ],
    // TODO: Future, Interests
    subMenu: [
    ]
}

export {
    landingPages
};

