import { render, JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import './banner.scss';

const svgBanner = (svg, heading, subtext) => {
  render(
    <div class="page-container">
      <div class="page-heading">
        <h2>{heading}</h2>
        <p>{subtext}</p>
      </div>
      {svg}
    </div>
  )
}

export {
  svgBanner
}

