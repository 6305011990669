import { modulePage, PageContent, JSXFactory, patch } from '@appzuka/rxnano';

import { svgBanner } from 'site/components/headingBanner/banners'

// import './services.scss';

import { skillsContent as skillsLogos } from 'site/components/skills/skills'
import { serviceBlocks } from 'site/components/serviceBlocks/serviceBlocks'
import { mobileAppPageContent } from './apps';
import { webDesignPageContent, webDesignNextStepsContent } from './webDesign';

const serviceLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "serviceSVG" */ './serviceContent');
};

const wdsvg = (pco) => modulePage(pco, 'webdesign', 'serviceSVG', serviceLoader);

const servicesBanner = (pco) => {
    svgBanner(() => serviceBlocks(pco), 'Services', 'We can manage your web or mobile project or use our skills to help you with yours');
}

const webDesignBanner = (pco) => {
    svgBanner(() => wdsvg(pco), 'Web Design', '"Perfection is Achieved Not When There Is Nothing More to Add, But When There Is Nothing Left to Take Away"');
}

const nextStepsBanner = (pco) => {
    svgBanner(() => wdsvg(pco), 'Web Design - Next Steps', 'Here\'s How to Get Your Own Blisteringly Fast Website to Impress Your Clients');
}

const appDevBanner = (pco) => {
    svgBanner(() => wdsvg(pco), 'Mobile Apps', 'Your own native mobile app on the app store');
}

const consultingBanner = (pco) => {
    svgBanner(() => wdsvg(pco), 'Consulting Services', 'Need help with your project? Please get in touch.');
}

const skillsContent = (pco) => {
    <render>
        <h2 class="home-mission">Full-Stack Developer with 30+ Years Coding, Marketing & Business Experience</h2>
        <div class="three-block">
            <p>Continually upgrading skills to stay at the leading edge of web and mobile technology.</p>
            <p>Reactive client-side technologies are the preferred toolset for the best customer experience.</p>
            <p>Declarative functional programming maximises reliability and productivity.</p>
        </div>
        <div id="skillslogos"></div>
    </render>
    // TODO: Cancel if page changed
    // TODO: Wait for page scroll to load
    const el = document.getElementById("skillslogos") as HTMLElement
    patch(el, () => skillsLogos(pco, 100))
}

const servicesContent = (pco) => {
    <render>
        <h2 class="home-mission">Full-Stack Developer with 30+ Years Coding, Marketing & Business Experience</h2>
        <div class="three-block">
            <p>Continually upgrading skills to stay at the leading edge of web and mobile technology.</p>
            <p>Reactive client-side technologies are the preferred toolset for the best customer experience.</p>
            <p>Declarative functional programming maximises reliability and productivity.</p>
        </div>
        <div id="skillslogos"></div>
    </render>
    // TODO: Cancel if page changed
    // TODO: Wait for page scroll to load
    const el = document.getElementById("skillslogos") as HTMLElement
    patch(el, () => skillsLogos(pco, 100))

    // const el2 = document.getElementById("skillslogos") as HTMLElement
    // patch(el, () => skillsLogos(pco, 100));
}

const servicePages : PageContent = {
    content: [
        {
            pageLocation: 'services',
            image: servicesBanner,
            pageTitle: 'Services',
            content: (pco) => servicesContent(pco),
        },
        {
            pageLocation: 'services/web',
            image: (pco) => webDesignBanner(pco),
            pageTitle: 'WebDesign',
            content: (pco) => webDesignPageContent(pco),
            name: 'web-design'
        },
        {
            pageLocation: 'services/web/nextsteps',
            image: (pco) => nextStepsBanner(pco),
            pageTitle: 'WebDesign',
            content: (pco) => webDesignNextStepsContent(pco),
            name: 'web-design'
        },
        {
            pageLocation: 'services/app',
            image: (pco) => appDevBanner(pco),
            pageTitle: 'Mobile Apps',
            content: (pco) => mobileAppPageContent(pco),
            name: 'mobile-apps'
        },
        {
            pageLocation: 'services/consulting',
            image: (pco) => consultingBanner(pco),
            pageTitle: 'Consulting',
            content: (pco) => skillsContent(pco),
            name: 'consulting'
        },

    ],
    // TODO: Future, Interests
    subMenu: [
        { label: 'services', link: '/services'},
        { label: 'web design', link: '/services/web'},
        { label: 'app development', link: '/services/app'},
        { label: 'consulting', link: '/services/consulting'}
    ]
}

export {
    servicePages
};

