let Auth = false;

const ImportAuth = () => {
    if (!Auth) console.log(`importing auth bundle`);

    return import(
        /* webpackMode: "lazy",
        webpackChunkName: "auth" */
        '@appzuka/auth').then(async m => {
            if (!Auth) {
                await m.RestoreUser();
                Auth = true;
            }
            return m;
        });
};

const GetCognitoUser = (username?) => ImportAuth().then(m => m.getCognitoUser(username));
const GetCredentials = (idToken) => ImportAuth().then(m => m.GetCredentials(idToken));
const AWSLogin = (username, password) => ImportAuth().then(m => m.AWSLogin(username, password));
const ConfirmUser = (cu, code) => ImportAuth().then(m => m.ConfirmUser(cu, code));
const AuthSignout = () => ImportAuth().then(m => m.Signout());
// const AuthStatus = () => ImportAuth().then(m => m.LoginStatus());
const RestoreUser = () => ImportAuth().then(m => m.RestoreUser());
const AuthData = () => ImportAuth().then(m => m.authData);

export {
    AWSLogin,
    AuthData,
    AuthSignout,
    RestoreUser,
    ConfirmUser,
    GetCognitoUser,
    GetCredentials
};
