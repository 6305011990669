import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';


import { BehaviorSubject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import { messageContent, authContent } from './index';
import { AWSLogin } from './authImports';
import { verifyInputs } from '././verifypassword';

import './loginform.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';

const formData = new BehaviorSubject({email: '', password: ''});

const emailSubject = new BehaviorSubject(false);
const passwordSubject = new BehaviorSubject(false);

const submitObserver = combineLatest(emailSubject, passwordSubject).pipe(
    // tap(v => console.log(v))
);

const submitInput = (pageChangeObservable, options) => {

    const el = elementOpen('input', null, ['type', 'submit', 'value', 'Submit']);
    elementClose('input');

    submitObserver
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(v => {
        if (v.reduce((a,c) => a && c, true)) {
        // if (true) {
            el.removeAttribute('disabled');
            el.style.backgroundColor=options.highlight;
        } else {
            el.setAttribute('disabled', 'disabled')
            el.style.backgroundColor='';
        }
    });
}

const emailInput = (pageChangeObservable, label, options) => {

    elementOpen('div', null, ['class', 'input-element']);
        const el = elementVoid('input', 'email-input', ['type', 'email']) as HTMLInputElement;
        const labelel = elementOpen('p', 'email-label', ['class', 'input-label']);
            text(label)
            elementOpen('span', null);
                text('You must enter a valid email address');
            elementClose('span');
        elementClose('p');

    elementClose('div');

    el.style.borderBottomColor=options.highlight;

    const source = fromEvent(el, 'input');
    const inputBlur = fromEvent(el, 'blur');

    const validInput = () => el.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;


    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (el.value.length > 0) {
                labelel.classList.add("input-non-empty");
            } else {
                labelel.classList.remove("input-non-empty");
            }
        }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el.style.backgroundColor = '';
                emailSubject.next(true);
                labelel.classList.remove('input-invalid');
            } else {
                el.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                emailSubject.next(false);
                labelel.classList.add('input-invalid');
            }
        }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
                el.value = data.email;
                if (data.email.length > 0) {
                    labelel.classList.add("input-non-empty");
                } else {
                    labelel.classList.remove("input-non-empty");
                }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el.style.backgroundColor = '';
                labelel.classList.remove('input-invalid');
                emailSubject.next(true);
            } else {
                emailSubject.next(false); 
            }
            formData.next({...formData.value, email: el.value})
        }
    );
}

const passwordInput = (pageChangeObservable, label, options) => {

    elementOpen('div', null, ['class', 'input-element']);
        const el2 = elementVoid('input', 'password-input', ['type', 'password']) as HTMLInputElement;
        const labelel2 = elementOpen('p', 'password-label', ['class', 'input-label']);
            text(label)
            elementOpen('span', null);
                text('Password must be at least 6 characters long');
            elementClose('span');
        elementClose('p');

    elementClose('div');

    el2.style.borderBottomColor=options.highlight;

    const source = fromEvent(el2, 'input');
    const inputBlur = fromEvent(el2, 'blur');

    const validInput = () => el2.value.length > 5

    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (el2.value.length > 0) {
                labelel2.classList.add("input-non-empty");
            } else {
                labelel2.classList.remove("input-non-empty");
            }
        }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                passwordSubject.next(true);
                labelel2.classList.remove('input-invalid');
            } else {
                el2.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                passwordSubject.next(false);
                labelel2.classList.add('input-invalid');
            }
        }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
                el2.value = data.password;
                if (data.password.length > 0) {
                    labelel2.classList.add("input-non-empty");
                } else {
                    labelel2.classList.remove("input-non-empty");
                }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                labelel2.classList.remove('input-invalid');
                passwordSubject.next(true);
            } else {
                passwordSubject.next(false); 
            }
            formData.next({...formData.value, password: el2.value})
        }
    );
}

const submitForm = (e, options) => {
    e.preventDefault();
    const loginData = formData.getValue();
    AWSLogin(loginData.email, loginData.password)
        .then(result => {
            if (result.code === 'ok')
                messageContent.next('Login successful')
            else if (result.code === 'newpassword') {
                authContent.next({action: 'forcechangepassword', actiondata: {cognitoUser: result.cognitoUser, email: result.email}});
                // const attributesData = {};
                // result.cognitoUser!.completeNewPasswordChallenge('newPassword', attributesData, {
                //     onSuccess: (session) => {
                //         console.log('Password challenge success');
                //         messageContent.next('Change Password & Login successful')
                //     },
                //     onFailure: (err) => {
                //         messageContent.next('Change Password & Login Failed')
                //         console.log('Password challenge on Failure');
                //         console.log(err);
                //     }
                // })
            } else {
                messageContent.next('Unhanded return: ' + result.code);
            }
        })
        .catch(err => {
            messageContent.next('Login Failed')
            console.log('Login error')
            console.log(err)
        });
}

const loginForm = (pco, options) => {
    const {password, verify} = verifyInputs();

    return({
        account: <fragment>
            <div class="login-form-wrapper">
                <form onsubmit={(e) => submitForm(e, options)}>
                    {() => emailInput(pco, 'email', options)}
                    <p class="privacy-note">This page is for authorised clients only.</p>
                    {() => passwordInput(pco, 'password', options)}
                    <div class="submit-area">
                        {() => submitInput(pco, options)}
                        <p class="privacy-note">By submitting your details, you agree to this site's terms and the processing of your data in accordance with our <a target="_blank" href='/policies/privacy'>privacy policy.</a></p>
                    </div>


                </form>
            </div>
        </fragment>,
        action: <fragment>
            <div class="action-wrapper">
                <p>If you have forgotton your password press the button below to have a reset code sent to your email address.</p>
                <input type="button" class="ghost" onclick={(e) => authContent.next({action: 'forgot', actiondata: {email: 'xxx.xxx.com'}})} value="Forgot Password"></input>
                
                <div class="login-form-wrapper">
                    <form onsubmit={(e) => {console.log("submit")}}>
                        {() => password(pco, options)}
                        {() => verify(pco, options)}
                    </form>
                </div>
            </div>
        </fragment>
    });
}

export {
    loginForm
};

