// eslint-disable-next-line no-unused-vars
import { PageContent, modulePage } from '@appzuka/rxnano';

// const aboutLoader = () => {
//     return import(
//       /* webpackMode: "lazy",
//          webpackChunkName: "aboutcontent" */
//       'site/pages/about/aboutContent.tsx');
// };

const aboutLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "about" */ './aboutContent');
};

const aboutPages = {
  content: [
    {
      pageLocation: 'about',
      image: {imageName: 'banners/fastcar', title: 'About Us', variantArray: [['mobile', 550], ['wide', 1200], ['pano']]},
      pageTitle: 'About',
      content: (pco) => {
        // import(/* webpackMode: "lazy", webpackChunkName: "about" */ './aboutContent').then(m => {    window.console.log('***************** loaded');})
        modulePage(pco, 'about', 'about', aboutLoader);
      }
    },
    {
      pageLocation: 'about/approach',
      image: ['banners/fastcar', 'About appZuka - Our Approach'],
      pageTitle: 'About appZuka',
      content: (pco) => modulePage(pco, 'approach', 'about', aboutLoader),
      name: 'approach'
    },
    {
      pageLocation: 'about/journey',
      image: ['banners/fastcar', 'About appZuka - Our Journey'],
      pageTitle: 'About appZuka',
      content: (pco) => modulePage(pco, 'journey', 'about', aboutLoader),
      name: 'journey'
    },
    {
      pageLocation: 'about/stack',
      image: ['banners/fastcar', 'About appZuka - Our Stack'],
      pageTitle: 'About appZuka - Our Stack',
      content: (pco) => modulePage(pco, 'stack', 'about', aboutLoader),
      name: 'stack'
    }
  ],
  // TODO: Future, Interests
  subMenu: [
    { label: 'about', link: '/about' },
    { label: 'approach', link: '/about/approach' },
    { label: 'journey', link: '/about/journey' },
    { label: 'stack', link: '/about/stack' }
  ]
} as PageContent;

export {
  aboutPages
};

