// eslint-disable-next-line no-unused-vars
import { modulePage, PageContent } from '@appzuka/rxnano';

const performanceLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "performance" */ './performanceContent');
};

const performancePages: PageContent = {
  content: [
    {
      pageLocation: 'performance',
      image: ['banners/portfolio', 'Performance'],
      pageTitle: 'performance',
      content: (pco) => modulePage(pco, 'performance', 'performance', performanceLoader),
      name: 'performance'
    },
    {
      pageLocation: 'performance/lighthouse',
      image: ['banners/portfolio', 'Lighthouse'],
      pageTitle: 'Lighthouse',
      content: (pco) => modulePage(pco, 'lighthouse', 'performance', performanceLoader),
      name: 'lighthouse'
    },
    {
      pageLocation: 'performance/webpagetest',
      image: ['banners/portfolio', 'Webpagetest'],
      pageTitle: 'webpagetest',
      content: (pco) => modulePage(pco, 'webpagetest', 'performance', performanceLoader),
      name: 'webpagetest'
    }
  ],
  subMenu: [
    { label: 'performance', link: '/performance' },
    { label: 'lighthouse', link: '/performance/lighthouse' },
    { label: 'webpagetest', link: '/performance/webpagetest' }
  ]
};

export {
  performancePages
};

