import {
    elementOpen,
    elementClose,
} from 'incremental-dom';

import { sitePix, linkClick } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import './portfolioCarousel.scss';

// TODO: DRY
const portfolioCarouselContent = () => {
    const list = [
        ['procom', 'Procom', 'Reactive Website'],
        ['panodrone', 'Panodrone', 'Website with video'],
        ['dharma', 'Recovery Dharma', 'News Website'],
        ['harkstead', 'Harkstead Village', 'Community Website'],
        ['farside', 'Far Side Films', 'Media Website'],
        ['fifthsense', 'FifthSense', 'e-commerce Website'],
        ['meadones', 'Meadon Editorial Services', 'Business Website'],
        ['alexander', 'Dean Alexander Conditioning', 'Personal Trainer Website']
    ];

    list.map(([id, title, name], i) => {
        elementOpen('a', `portfolio-link-${id}`, null, 'href', `\/portfolio\/${id}`,
        'class', `portfolio-square portfolio-square-${id} portfolio-square-${i} carousel-node carousel-node-${i}`,
        'onclick', (event) => {
            linkClick(event, `/portfolio/${id}`);
        });
            sitePix(SiteData.assetBase, `portfolio/${id}`);
        elementClose('a');
    });
}


export {
    portfolioCarouselContent
};

