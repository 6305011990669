import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';


import { BehaviorSubject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import { messageContent, authContent } from './index';
import { RestoreUser } from './authImports';

import './loginform.scss';

const formData = new BehaviorSubject({password: ''});

const passwordSubject = new BehaviorSubject(false);

const submitObserver = combineLatest(passwordSubject).pipe(
    // tap(v => console.log(v))
);

const submitInput = (pageChangeObservable, options) => {

    const el = elementOpen('input', null, ['type', 'submit', 'value', 'Submit']);
    elementClose('input');

    submitObserver
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(v => {
        if (v.reduce((a,c) => a && c, true)) {
        // if (true) {
            el.removeAttribute('disabled');
            el.style.backgroundColor=options.highlight;
        } else {
            el.setAttribute('disabled', 'disabled')
            el.style.backgroundColor='';
        }
    });
}

const passwordInput = (pageChangeObservable, label, options) => {

    elementOpen('div', null, ['class', 'input-element']);
        const el2 = elementVoid('input', 'password-input', ['type', 'password']) as HTMLInputElement;
        const labelel2 = elementOpen('p', 'password-label', ['class', 'input-label']);
            text(label)
            elementOpen('span', null);
                text('Password must be at least 6 characters long');
            elementClose('span');
        elementClose('p');

    elementClose('div');

    el2.style.borderBottomColor=options.highlight;

    const source = fromEvent(el2, 'input');
    const inputBlur = fromEvent(el2, 'blur');

    const validInput = () => el2.value.length > 5

    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (el2.value.length > 0) {
                labelel2.classList.add("input-non-empty");
            } else {
                labelel2.classList.remove("input-non-empty");
            }
        }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                passwordSubject.next(true);
                labelel2.classList.remove('input-invalid');
            } else {
                el2.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                passwordSubject.next(false);
                labelel2.classList.add('input-invalid');
            }
        }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
                el2.value = data.password;
                if (data.password.length > 0) {
                    labelel2.classList.add("input-non-empty");
                } else {
                    labelel2.classList.remove("input-non-empty");
                }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                labelel2.classList.remove('input-invalid');
                passwordSubject.next(true);
            } else {
                passwordSubject.next(false); 
            }
            formData.next({...formData.value, password: el2.value})
        }
    );
}

const submitForm = (e, options, cognitoUser) => {
    e.preventDefault();

    const attributesData = {};
    cognitoUser!.completeNewPasswordChallenge(formData.getValue().password, attributesData, {
        onSuccess: (session) => {
            console.log('Password change success');
            RestoreUser();
            messageContent.next('Change Password & Login successful')
            authContent.next({action: 'account'});
        },
        onFailure: (err) => {
            messageContent.next('Change Password & Login Failed')
            console.log('Password change Failure');
            console.log(err);
        }
    });

}

const forceChangePasswordForm = async (pco, options, cognitoUser, email) => {

    console.log(email);

    <render>
        <div class="login-form-wrapper">
            <form onsubmit={(e) => submitForm(e, options, cognitoUser)}>
                <p>New password required for user: {email}</p>
                {() => passwordInput(pco, 'password', options)}
                <div class="submit-area">
                    {() => submitInput(pco, options)}
                    <input type="button" onclick={(e) => authContent.next({action: 'account'})} value="Cancel"></input>
                </div>

            </form>
        </div>
    </render>
}

export {
    forceChangePasswordForm
};

