import {
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import { modulePage, PageContent } from '@appzuka/rxnano';

import { articleCategoryIndex, articleIndex, searchIndex, codingIndex, performanceIndex } from './noteList';

import './articlePages.scss';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

// const aboutLoader = () => {
//     return import(
//       /* webpackMode: "lazy",
//          webpackChunkName: "aboutcontent" */
//       'site/pages/about/aboutContent.tsx');
// };

const searchArticleLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "search-articles" */ './search/cleaning');
};

const codingArticleLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "coding-articles" */ './coding/typescript');
};

const showArticle = (pco, module) => {
    module(pco);
    <render>
        <h2>Other Tech Notes</h2>
    </render>
    articleCategoryIndex();
}

const notePages : PageContent = {
    content: [
        {
            pageLocation: 'technotes',
            image: ['banners/fastcar', 'Tech Notes'],
            pageTitle: 'tech Notes',
            content: (pco) => {
                <render>
                    <h2>Tech Notes</h2>
                    <p>We like to share what we have learnt along our journey.  If you are interested in these subjects please get in touch and we look forward to discussing with you.</p>
                </render>
                articleIndex();
            },
        },
        {
            pageLocation: 'technotes/coding',
            image: ['banners/fastcar', 'Tech Notes - Coding'],
            pageTitle: 'Coding',
            content: (pco) => codingIndex(),
            name: 'coding'
        },
        {
            pageLocation: 'technotes/coding/typescript',
            image: ['banners/fastcar', 'Coding - Typescript'],
            pageTitle: 'Coding: Typescript',
            content: (pco) =>showArticle(pco, (pco) =>  modulePage(pco, 'typescript', 'coding-articles', codingArticleLoader, {loaderOptions: {class: 'article-content'}})),
            name: 'coding'
        },
        {
            pageLocation: 'technotes/performance',
            image: ['banners/fastcar', 'Tech Notes - Performance'],
            pageTitle: 'Performance',
            content: (pco) => performanceIndex(),
            name: 'performance'
        },
        {
            pageLocation: 'technotes/search',
            image: ['banners/fastcar', 'Tech Notes - Search'],
            pageTitle: 'Search',
            content: (pco) => searchIndex(),
            name: 'search'
        },
        {
            pageLocation: 'technotes/search/cleaning',
            image: ['banners/fastcar', 'Cleaning Outdated Content from GoogleTech Notes'],
            pageTitle: 'Search',
            content: (pco) => showArticle(pco,  (pco) => modulePage(pco, 'cleaning', 'search-articles', searchArticleLoader, {loaderOptions: {class: 'article-content'}})),
            name: 'search'
        },

    ],
    // TODO: Future, Interests
    subMenu: [
        { label: 'tech notes', link: '/technotes'},
        { label: 'coding', link: '/technotes/coding'},
        { label: 'performance', link: '/technotes/performance'},
        { label: 'search', link: '/technotes/search'}
    ]
}

export {
    notePages
};

