import {
    elementOpen,
    elementClose,
    skip
} from 'incremental-dom';

import { modulePage } from '@appzuka/rxnano';

import './consultingSkills.scss';

const skillsLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "skills" */ './skillsContent');
};

import './consultingSkills.scss';

const skillsContent = (pco, delay = 0) => {

    elementOpen('div', null, null, 'class', `skills-list`);
        modulePage(pco, 'skills', 'skills', skillsLoader, {loaderOptions: {delay: delay},  a: 42}),
    elementClose('div');
};

export {
    skillsContent
};
