import {
    elementOpen,
    elementClose,
    elementVoid,
    patch,
    text
} from 'incremental-dom';

import { markup } from 'site/utils';
import { sitePix, linkClick } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

// import { Global } from "site/globals";

const articleList = [
    {
        title: 'WebPageTest',
        snippet: 'Diagnosing and fixing performance issues',
        category: 'performance',
        image: 'performance',
        link: '/technotes/performance/webpagetest',
        ribbon: 'coming soon',
        tags: ['performance', 'webpagetest', 'lighthouse'],
    },
    {
        title: 'Lighthouse Auditing',
        snippet: 'Measuring Website Performance',
        category: 'performance',
        image: 'performance',
        link: '/technotes/performance/lighthouse',
        ribbon: 'coming soon',
        tags: ['performance', 'webpagetest', 'lighthouse'],
    },
    {
        title: 'Why Typescript?',
        snippet: 'How Typescript improves code quality and productivity',
        category: 'coding',
        image: 'coding',
        link: '/technotes/coding/typescript',
        ribbon: 'new',
        tags: [],
    },
    {
        title: 'Cleaning Outdated Content from Google',
        snippet: 'Why it is sometimes hard to remove results from Google\'s index',
        category: 'search',
        image: 'search',
        link: '/technotes/search/cleaning',
        ribbon: 'new',
        tags: [],
    }
]

// // TODO: Duplicate of portfolioPix - DRY
// // TODO: Check media sizes
// const articlePix = (id, key, mediaSizes?) => {

//     const allPix = getStorePix(``).filter(p => p.file === `site/thumbnails/${id}`);

//     const portfolioPixContent = (allPix) => {
//         const thisPix = allPix[0];
//         const defaultSize = thisPix.sizes.sort((a,b) => {return ((a-400)**2 < (b-400)**2)?-1:+1})[0]; // With the size closest to 400
//         const {file, hash, sizes, pathprefix} = thisPix;

//         const webpSrc = sizes.map(s => `${Global.assetBase}${pathprefix}/${file}-${hash}-${s}.webp ${s}w`).join();
//         const jpgSrc = sizes.map(s => `${Global.assetBase}${pathprefix}/${file}-${hash}-${s}.jpg ${s}w`).join();

//         elementVoid('source', 'site-webp-source', ['type', 'image/webp', 'srcset',  webpSrc, 'sizes',
//           mediaSizes || '(max-width: 499px) 50vw, ((min-width: 500px) and (max-width: 768px)) 33vw, 25vw']);
//         elementVoid('source', 'site-jpg-source', ['srcset',  jpgSrc, 'sizes', mediaSizes]);

//         elementVoid('img', null, ['src', `${Global.assetBase}${pathprefix}/${file}-${hash}-${defaultSize}.jpg`]) as HTMLImageElement;
//     };

//     if (allPix.length > 0) {
//         elementOpen('picture', `about-pix-${id}-${key}`, ['id', `about-pix-${id}-${key}`, 'class', 'article-thumbnail-image']);
//             portfolioPixContent(allPix);
//         elementClose('picture');
//     } else {
//         elementVoid('picture', `about-pix-${id}-${key}`, ['id', `about-pix-${id}-${key}`, 'class', 'article-thumbnail-image']);
//         addToPageQueue({run: function patchPortfolioPix() {
//             const el2 = document.getElementById(`about-pix-${id}-${key}`);
//             if (el2 !== null) {
//                 patch(el2, () => {
//                     const allPix = getStorePix(``).filter(p => p.file === `site/thumbnails/${id}`);
//                     portfolioPixContent(allPix);
//                 });
//             }
//         }, name: 'addBanner', holdFor: [{event: KernelEvent.pixListLoaded}]});

//     }
// };

const articleThumbnail = (article, key) => {
    let {title, snippet, category, image, link, ribbon} = article;

    const thumbNailContent = (article, key) => {
        if (ribbon) {
            elementOpen('div', null, ['class', 'ribbon']);
            elementOpen('span');
                text(ribbon);
            elementClose('span');
        elementClose('div');
        }

        elementOpen('div', null, ['class', 'article-thumbnail-wrapper']);
                // sitePix(assetBase, `site/thumbnails/${image}`, {class: 'article-thumbnail-image', mediaSizes: '(max-width: 949px) 33vw, 16vw'});
                sitePix(SiteData.assetBase, `site/thumbnails/${image}`, {class: 'article-thumbnail-image'});
            elementVoid('div', null, ['class', 'article-thumbnail-image-filter']);
            elementOpen('h2');
                text(title);
            elementClose('h2');
            elementOpen('p');
                text(snippet);
            elementClose('p');
        elementClose('div');
    }
     

    if (ribbon !== 'coming soon') {
        elementOpen('a', `article-thumbnail-${category}-${key}`, [
            'class', 'article-thumbnail',
            'href', link,
            'onclick', (event) => linkClick(event, link)
        ]);
            thumbNailContent(article, key);
        elementClose('a');
    } else {
        elementOpen('div', `article-thumbnail-${category}-${key}`, ['class', 'article-thumbnail']);
            thumbNailContent(article, key);
        elementClose('div');
    }

}

const articleIndex = () => {
    performanceIndex();
    codingIndex();
    searchIndex();

};

const articleCategoryIndex = (category = 'includeall', thisTitle = 'includeall') => {
    elementOpen('div', null, ['class', 'article-thumbnail-container']);
        articleList.filter(a => ((category === 'includeall') || (a.category.indexOf(category) > -1)) && (a.title !== thisTitle)).map((a,i) => {
            articleThumbnail(a, i);
        });
    elementClose('div');
};

const performanceIndex = () => {
    markup(`h2 Tech Notes on Performance and Optimisation
    p Click on an article below to learn more of what we have done.`);
    articleCategoryIndex('performance');

};

const codingIndex = () => {
    markup(`h2 Tech Notes on Coding
    p Click on an article below to learn more of what we have done.`);
    articleCategoryIndex('coding');
};

const searchIndex = () => {
    markup(`h2 Tech Notes on Search and SEO
    p Click on an article below to learn more of what we have done.`);
    articleCategoryIndex('search');
};

// const mc = {
//     "articleindex": articleIndex,
//     "performanceindex": performanceIndex,
//     "codingindex": codingIndex,
//     "searchindex": searchIndex,
//     "categoryindex": articleCategoryIndex
// };

export {
    articleIndex,
    performanceIndex,
    codingIndex,
    searchIndex,
    articleCategoryIndex
};
