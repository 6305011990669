import {
  elementOpen,
  elementVoid,
  elementClose,
  text
} from 'incremental-dom';

import { rxnanoIcons, ContactType } from '@appzuka/rxnano';

import { ISiteData } from "rxnanoModels";

const CommitInfo = require('virtual/commitinfo.js');

// import { type } from 'os';

interface FooterContactLine {
  type: ContactType,
  icon: any,
  label: string;
  target: string
}

type FooterContactArray = FooterContactLine[];

export const MapsAPIKey = 'AIzaSyAWGm7lJKidOYOZwusjGXLXgJApnXf5XQ8';

export const FooterContacts: FooterContactArray = [
  { type: ContactType.Link, icon: rxnanoIcons.worldIcon, label: 'www.appzuka.io', target: '/' },
  { type: ContactType.Mail, icon: rxnanoIcons.emailIcon, label: 'info@appzuka.io', target: 'info@appzuka.io' },
  { type: ContactType.Line, icon: rxnanoIcons.phoneIcon, label: '+44 7789 928955', target: '' },
  { type: ContactType.External, icon: rxnanoIcons.linkedinIcon, label: 'linkedin', target: 'https://www.linkedin.com/in/nickexcell/' }
];

export const FooterAddress: FooterContactArray = [
  { type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Guildford', target: '/contact' },
  { type: ContactType.Line, icon: undefined, label: 'We are located in central Guildford, Surrey, UK', target: '' },
  { type: ContactType.Link, icon: rxnanoIcons.mailIcon, label: 'Contact Us', target: '/contact' }
];


export const appZukaLogo = (logoClass: string) => {

  const chars = [
    "M101.21,67.64h5.35V66.31c0-24.39-9.69-33.41-31.4-33.41-7.69,0-22.72,2-22.72,11.36v9H13v-18C13,3.51,57.79.17,75.49.17,132.28.17,149,29.9,149,68v63.46c0,4.68,2.67,7.35,7.35,7.35h13.36v35.74H131.61c-15.7,0-21.71-8.68-21.71-18.7,0-4.35.33-7.35.33-7.35h-.67s-13,30.06-51.44,30.06c-30.4,0-58.12-19-58.12-53.11C0,72,73.49,67.64,101.21,67.64ZM68.48,145.47c22.71,0,38.74-24,38.74-44.76v-4H99.88c-22,0-57.12,3-57.12,26.73C42.76,134.78,51.11,145.47,68.48,145.47Z",
    "M203.76,47.6c0-5-2.68-7.35-7.35-7.35H183.05V4.18h37.74c15.7,0,21.38,7,21.38,16.36v6.69h.67S257.2.17,297,.17c46.1,0,75.83,36.41,75.83,89.18,0,54.12-33.41,89.19-78.17,89.19-33.73,0-48.77-22.38-48.77-22.38h-.66s.66,6,.66,14.7l-.28,66.75H203.72Zm83.17,95.2c23.38,0,43.09-19,43.09-52.78,0-32.4-17.37-53.44-42.42-53.44-22.05,0-42.76,16-42.76,53.78C244.84,116.75,258.87,142.8,286.93,142.8Z",
    "M411.18,47.6c0-5-2.67-7.35-7.34-7.35H390.47V4.18h37.75c15.7,0,21.38,7,21.38,16.36v6.69h.67S464.63.17,504.38.17c46.1,0,75.82,36.41,75.82,89.18,0,54.12-33.4,89.19-78.16,89.19-33.74,0-48.77-22.38-48.77-22.38h-.67a146.82,146.82,0,0,1,.67,14.7l0,66.59H411.39Zm83.18,95.2c23.38,0,43.09-19,43.09-52.78,0-32.4-17.37-53.44-42.42-53.44-22,0-42.76,16-42.76,53.78C452.27,116.75,466.3,142.8,494.36,142.8Z",
    "M604.57,209.44l106.55-151a173,173,0,0,1,18-21.38v-.67s-7,.67-18,.67H653.67c-4.68,0-7.35,2.67-7.35,7.35V58.79H606.57V22.71C606.57,7,613.25,0,629.28,0h154V27.39L676.72,178.71a146.46,146.46,0,0,1-18,21.37v.67s7-.67,18-.67h64.8c4.67,0,7.35-2.67,7.35-7.34V178.37h39.41v36.08c0,16-6.68,22.71-22.71,22.71h-161Z",
    "M822.35,110.23c0-5-2.67-7.35-7.35-7.35H801.64V66.81h40.42c15.7,0,22.38,7,22.38,22.38v78.49c0,20.71,5.34,34.74,26.39,34.74,30.73,0,47.76-27,47.76-57.45V66.81H981V194.07c0,4.68,2.67,7.35,7.34,7.35h13.37v35.74H962.64c-14.69,0-22.38-7-22.38-19v-4.67c0-4,.34-7.69.34-7.69h-.67c-8,17.71-29.39,35.41-58.79,35.41-36.41,0-58.79-18.37-58.79-64.8Z",
    "M1034.79,43.42c0-5-2.67-7.34-7.35-7.34h-13.36V0h40.42c15.7,0,22.71,3.67,22.71,19.37V126.93h12.69c5.35,0,12.36-1,16-6.35l34.74-53.77h47.09l-43.08,64.13c-7.35,10.69-12.7,13.36-12.7,13.36V145s6,2.34,12,13.36l19.37,36.74c2.67,5,6,6.35,14,6.35h11.36v35.74h-31.4c-14.36,0-20-2.34-26.72-14.7L1102.26,169c-3.34-6-10.35-6.35-15.7-6.35h-9.35v74.49h-42.42Z",
    "M1304.35,130.27h5.34v-1.33c0-24.39-9.68-33.41-31.4-33.41-7.68,0-22.71,2-22.71,11.36v9h-39.41v-18c0-31.73,44.75-35.07,62.46-35.07,56.78,0,73.49,29.73,73.49,67.81v63.46c0,4.68,2.67,7.35,7.34,7.35h13.37v35.74h-38.08c-15.7,0-21.72-8.68-21.72-18.7a72.22,72.22,0,0,1,.34-7.35h-.67s-13,30.06-51.44,30.06c-30.4,0-58.12-19-58.12-53.11C1203.14,134.61,1276.62,130.27,1304.35,130.27Zm-32.74,77.83c22.72,0,38.75-24,38.75-44.76v-4H1303c-22,0-57.12,3-57.12,26.73C1245.89,197.41,1254.24,208.1,1271.61,208.1Z"
  ];

  elementOpen('svg', null, null,
    'class', `site-icon ${logoClass}`,
    'viewBox', '0 0 1372.83 241.17'
  );
  elementOpen('title');
  text('Site Logo');
  elementClose('title');
  chars.map((c, i) => {
    // elementVoid('path', `al-${i}`, ['style', `fill: ${fillColor===null?i<3?'#525252':'#f18a32':fillColor};`, 'd', c]);
    elementVoid('path', `site-logo-${i}`, ['class', i < 3 ? 'text-app' : 'text-zuka', 'd', c]);
  });
  elementClose('svg');
}

export const HeaderSiteLogo = () => appZukaLogo('header-logo');
export const FooterSiteLogo = () => appZukaLogo('footer-logo');

interface HeadMenuItem {
  menuText: string
  link: string
  priority: number
}

type HeadMenuArray = HeadMenuItem[];
type DropMenuArray = HeadMenuArray[];

const defineMenuItem = (item: string, priority: number, link?: string): HeadMenuItem =>
  ({ menuText: item, priority: priority, link: link === undefined ? '/' + item.toLowerCase() : link });

export const PageLabels = {
  'about': 'about us',
  'landing': 'xxx'
};

export const HeadMenuContent: HeadMenuArray = [
  defineMenuItem('about', 7),
  defineMenuItem('services', 7),
  defineMenuItem('portfolio', 6),
  defineMenuItem('contact', 7)
];

export const TopMenuContent = HeadMenuContent;

type vf = (string?) => void;

// export const DropMenuContent = () => {console.log('dmf')};

export const DropMenuContent: DropMenuArray | vf = [
  [
    defineMenuItem('About Us', 1, '/about'),
    defineMenuItem('Home', 1, '/#portfolio'),
    defineMenuItem('web', 1, '/services/web'),
    defineMenuItem('Contact', 1),
    defineMenuItem('Client Login', 1, '/account'),
    defineMenuItem('Privacy', 1, '/policies/privacy'),
    defineMenuItem('Terms', 1, '/policies/terms')
  ],
  [
    defineMenuItem('Services', 1),
    defineMenuItem('Web Development', 1, '/services/web'),
    defineMenuItem('Mobile Apps', 1, '/services/app'),
    defineMenuItem('Performance', 1, '/performance'),
    defineMenuItem('Landing Pages', 1, '/landing')
  ],
  [
    defineMenuItem('Consulting', 1, '/services/consulting'),
    defineMenuItem('React', 1, '/services/consulting'),
    defineMenuItem('React Native', 1, '/services/consulting'),
    defineMenuItem('Rails', 1, '/services/consulting'),
    defineMenuItem('Lighthouse Speed Optimisation', 1, '/performance/lighthouse'),
    defineMenuItem('WebPageTest', 1, '/performance/webpagetest')
  ],
  [
    defineMenuItem('Portfolio', 1),
    defineMenuItem('Web', 1, '/portfolio'),
    defineMenuItem('Mobile', 1, '/portfolio'),
    defineMenuItem('*Tech Notes', 1, '/technotes'),
    defineMenuItem('Performance', 1, '/technotes/performance'),
    defineMenuItem('Coding', 1, '/technotes/coding'),
    defineMenuItem('Search', 1, '/technotes/search')
  ]
];

type SideMenuArray = Array<Array<Array<string>>>;

export const SideMenuContent: SideMenuArray | vf = [
  [
    ['About Us', '/about'],
    ['Home', '/'],
    ['Contact', '/contact'],
    ['Account', '/account'],
    ['Privacy', '/policies/privacy'],
    ['Terms', '/policies/terms']
  ],
  [
    ['Services', '/services'],
    ['Web Development', '/services/web'],
    ['Mobile Apps', '/services/app'],
    ['Landing Pages', '/landing'],
    // ['Search Optimisation', '/seo'],
    // ['Design Studio', '/design']
  ],
  [
    ['Consulting', '/services/consulting'],
    ['React', '/services/consulting'],
    ['React Native', '/services/consulting'],
    ['Rails', '/services/consulting'],
    ['Lighthouse Speed Optimisation', '/performance/lighthouse'],
    ['WebPageTest', '/performance/webpagetest']
  ],
  [
    ['Portfolio', '/portfolio'],
    ['Web', '/portfolio'],
    ['Mobile', '/portfolio']
  ],
  [
    ['TechNotes', '/technotes'],
    ['Performance', '/technotes/performance'],
    ['Coding', '/technotes/coding'],
    ['Search', '/technotes/search'],
  ],
  // [
  //   ['wildfoo', '/wild/foo'],
  //   ['wildbar', '/wild/bar'],
  //   ['wildbaranchor', '/wild/bar#hi'],
  // ]
];

interface ContactPageItem {
  icon: any,
  label: string,
  linkText: string,
  link?: string
}

type ContactPageArray = ContactPageItem[];

const defineContactPageItem = (icon: any, label: string, linkText: string, link?: string): ContactPageItem =>
  ({ icon: icon, label: label, linkText: linkText, link: link });


const londonAddress = {
  title: null,
  mapinfo: {
    latitude: 51.240220,
    longitude: -0.570917,
    zoom: 8,
    title: 'appZuka'
  },
  details: [
    defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'We are located in Guildford'),
    defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '+44 7789 928955'),
    defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.appzuka.io', '/'),
    defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@appzuka.io', 'mailto:info@appzuka.io'),
    defineContactPageItem(rxnanoIcons.linkedinIcon, 'linkedIn', 'www.linkedin.com', 'https://www.linkedin.com/in/nickexcell'),
    defineContactPageItem(rxnanoIcons.twitterIcon, 'twitter', 'twitter.com/appzuka', 'https://twitter.com/appzuka')
  ]
}

export const ContactAddressList = [
  () => { },
  londonAddress
]

export const SiteData: ISiteData = {
  ga: null,
  pageTitleBase: 'appZuka',
  assetBase: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/appzuka/imagestream/',
  fullpixlist: 'fullpixlist.json',
  index: CommitInfo.index,
  siteName: 'www.appzuka.io',
  highlight: '#f28b32',
  pageLabels: {
    '/about': 'about us',
    '/guildford-web-development': 'guildford',
  },
  cognitoConfig: {
    region: 'eu-west-1',
    clientID: '28toug1d6udh9ipmk1cbejd07q',
    userpool: 'eu-west-1_ktnvCHUat',
    identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
  },
  dynamodbConfig: {
    region: 'eu-west-2'
    // clientID: '28toug1d6udh9ipmk1cbejd07q',
    // userpool: 'eu-west-1_ktnvCHUat',
    // identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
  }
}

export const assetBase = 'https://appzuka-web.s3.eu-west-2.amazonaws.com/appzuka/imagestream/';

export const PrivacyContacts = {
  company: 'appZuka',
  policyDate: 'October 2019',
  contact: 'info@appzuka.io'
}

