import {
  elementOpen,
  elementClose,
  text
} from 'incremental-dom';

import { PageListItem, notFoundPage, contactPage, termsPages, linkClick, JSXFactory, render } from '@appzuka/rxnano';

import { aboutPages } from 'site/pages/about/aboutPages';
import { accountPages } from 'site/pages/account';
import { errorlogPages } from 'site/pages/errorlog';
import { landingPages } from 'site/pages/landing/landingPages';
import { notePages } from 'site/pages/technotes/techNotes';
import { portfolioPages } from 'site/pages/portfolio/portfolio';
import { performancePages } from 'site/pages/performance/performancePages';
import { homePages } from 'site/pages/home/home';
import { contactForm } from '@appzuka/rxnano-contactform';

// import { contactPage } from 'pages/contact';
import { servicePages } from 'site/pages/services/services';

import { SiteData, ContactAddressList, PrivacyContacts, DropMenuContent as dropmenu } from 'site/siteContent';

import './pages.scss';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Fix for narrow screens
const siteLinks = () => (<div class='hovermenucolumn xxx'>
  { () => typeof dropmenu === 'function'
          ? dropmenu(window.location.pathname)
          : <h2>Dropmenu</h2> }
</div>)();

const siteLinksx = () => {
  elementOpen('div', 'hmc', null, 'class', 'hovermenucontent');
  if (typeof dropmenu === 'function') {
    dropmenu(window.location.pathname);
  } else {
    dropmenu.map((c, j) => {
      elementOpen('div', null, null, 'class', 'hovermenucolumn');
      elementOpen('ul');
      c.map(({ menuText, link }, i) => {
        let classList, finalMenuText;
        if (menuText[0] === '*') {
          classList = ['class', 'drop-menu-spacer'];
          finalMenuText = menuText.slice(1);
        } else {
          classList = null;
          finalMenuText = menuText;
        }
        elementOpen('li', null, classList);
        elementOpen('a', `hmc-${link}-${i}`, null, 'href', link,
          'class', window.location.pathname === link ? 'active-link' : 'other-link',
          'onclick', (event) => {
            linkClick(event, link);
          });
        text(finalMenuText);
        elementClose('a');
        elementClose('li');
      });
      elementClose('ul');
      elementClose('div');
    });
  }
  elementClose('div');
};

// Copy submenu into each page
const pageData = (pages) => {
  return (pages.content.map(k => { return { ...k, subMenu: pages.subMenu, subMenuClass: pages.subMenuClass }; }));
};

// Pass in an empty function to omit the form
const siteContactForm = (pco, options?) => contactForm(pco, {
  ...options,
  testMode: !CommitInfo.production || CommitInfo.test,
  sourceSite: SiteData.siteName,
  highlight: SiteData.highlight,
  commitVersion: CommitInfo.commit
});

// const siteContactText =
//   <fragment>
//     <div class='contact-text'>
//       <p>You can get in touch with me through any of the following ways:</p>
//       <ul>
//         <li>Complete the contact form below</li>
//         <li>E-mail me directly at <a href="mailto:info@appzuka.io">info@appzuka.io</a></li>
//       </ul>
//       <p>I will respond to you as soon as I can.</p>
//       <p>I look forward to working with you!</p>
//     </div>
//   </fragment>

const pageList: PageListItem[] = [
  ...pageData(notFoundPage('banners/notfound', siteLinks)),
  ...pageData(contactPage([() => <render><h2>Contact</h2></render>, ...ContactAddressList], siteContactForm)),
  ...pageData(homePages),
  ...pageData(servicePages),
  ...pageData(aboutPages),
  ...pageData(accountPages),
  ...pageData(errorlogPages),
  ...pageData(landingPages),
  ...pageData(notePages),
  ...pageData(portfolioPages),
  ...pageData(performancePages),
  ...pageData(termsPages(PrivacyContacts))
];

export {
  pageList,
  siteContactForm
};

