
import {
    elementOpen,
    elementClose,
    patch,
    skipNode
} from 'incremental-dom';

import './apps.scss';
import '../webDesign/web-design.scss'
//

// TODO: confirm this only brings in these 3 items into the main bundle
import { androidIcon, appleIcon, reactIcon } from 'site/components/consultingSkills/icons/mainLogos';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

// import {
//     reactIcon
// } from 'site/modules/icons/main';

const trendData = `<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 278.7 206.7" style="enable-background:new 0 0 278.7 206.7;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#EAEAEA;}
	.st1{fill:none;stroke:#DD241F;stroke-width:2;}
</style>
<rect x="3.2" y="6.3" class="st0" width="272.8" height="197.3"/>
<path class="st1" d="M8,196.6h1.5H11l1.5-9.3L14,191h1.5l1.5,1.9h1.5H20h1.5l1.5,1.9l1.5-13l1.5,3.7l1.5,1.9l1.5,1.9l1.5-1.9
	l1.5,1.9h1.5h1.5l1.5,1.9l1.5-3.7l1.5,1.9h1.5l1.5,3.7l1.5-5.6l1.5,3.7l1.5-3.7l1.5,1.9h1.5l1.5,1.9l1.5-1.9h1.5h1.5l1.5-1.9
	l1.5,1.9l1.5-1.9l1.5-9.3l1.5,1.9l1.5,1.9l1.5-1.9l1.5,1.9h1.5l1.5,1.9l1.5-3.7h1.5h1.5l1.5,1.9l1.5,1.9l1.5-1.9l1.5-1.9l1.5,1.9
	h1.5l1.5-3.7l1.5-3.7h1.5l1.5,3.7l1.5-1.9l1.5-3.7l1.5-1.9h1.5l1.5-1.9l1.5-1.9h1.5l1.5,1.9l1.5-3.7l1.5,3.7l1.5-3.7l1.5-5.6
	l1.5,1.9l1.5,3.7l1.5-1.9l1.5-3.7h1.5h1.5l1.5-1.9h1.5l1.5,3.7l1.5-7.4l1.5,7.4h1.5l1.5-9.3h1.5h1.5h1.5l1.5,5.6l1.5-3.7h1.5
	l1.5-5.6l1.5,1.9l1.5-3.7l1.5,3.7l1.5,3.7l1.5-9.3l1.5-3.7l1.5-1.9l1.5-1.9l1.5,1.9l1.5-7.4l1.5,3.7h1.5l1.5-5.6l1.5-1.9l1.5,1.9
	l1.5,11.1l1.5-11.1l1.5-5.6l1.5-3.7h1.5l1.5,3.7l1.5-13l1.5-9.3l1.5,3.7l1.5,1.9l1.5-3.7l1.5-3.7l1.5-3.7l1.5-3.7l1.5,1.9l1.5,5.6
	l1.5-5.6l1.5,1.9h1.5l1.5-3.7h1.5h1.5h1.5l1.5-5.6h1.5l1.5-3.7l1.5,1.9l1.5-1.9l1.5-11.1l1.5,3.7l1.5-3.7l1.5-1.9l1.5,3.7h1.5
	l1.5-5.6l1.5,1.9l1.5,13l1.5-14.8l1.5-3.7l1.5,11.1l1.5-3.7l1.5-1.9h1.5l1.5,1.9l1.5-3.7l1.5-11.1l1.5,7.4l1.5-7.4l1.5,7.4l1.5-7.4
	l1.5,1.9l1.5,3.7L240,80l1.5-3.7l1.5-25.9l1.5-1.9l1.5,3.7l1.5-7.4l1.5,1.9l1.5,9.3l1.5-1.9l1.5-5.6l1.5-3.7l1.5-11.1l1.5,7.4
	l1.5-3.7l1.5,3.7l1.5-9.3l1.5,7.4l1.5-5.6l1.5,11.1l1.5-9.3l1.5-7.4l1.5-16.7"/>
</svg>`;

const trendGraph = () => {
    let el = elementOpen('div', null, ['class', 'trend-graph']);
        el.innerHTML = trendData;
        skipNode(); // Don't clear out children
    elementClose('div');
};

// Accents summer from adobe color
const themeColor = ['#C64C54', '#FABF50', '#79B6B8', '#994D7E', '#F79781'];

const mobileAppPageContent = (pco) => {
    <render>
        <div class="page-standard full-width no-bottom-padding">
            <div class="flex-row">
                <div class="flex-row-left flex-row-content" style={`background-color: ${themeColor[0]}; color: white`}>
                    <h2>Offer Your Own App</h2>
                    <p>Recent advances in technology mean that true, native apps can be created using the same technology as websites.</p>
                    <p>A native will be installed through the iOS app store (for iPhone and iPad) and the Google Play store for android devices.</p>
                    <p>Native apps:</p>
                    <ul>
                        <li>Appear on the home screen</li>
                        <li>Can be used without an internet connection</li>
                        <li>Are fast and smooth</li>
                    </ul>
                    <p>Give your business an edge with your own mobile app.</p>
                </div>
                <div class="flex-row-right flex-row-image" style={`background-color:  white`}>
                    <div class="app-store-icons">
                        <div class="app-store-icon-badge">
                            {appleIcon}
                            <h2>iOS App Store</h2>
                        </div>
                        <div class="app-store-icon-badge">
                            {androidIcon}
                            <h2>Google Play Store</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-row flex-row-reverse">
                <div class="flex-row-left flex-row-content" style={`background-color: ${themeColor[3]}; color: white`}>
                    <h2>App Development has Changed</h2>
                    <p>When the iOS and Android were released app development was complex and expensive.  The tools required specialist knowledge and were completely different from web development tools.</p>
                    <p>In 2015 React Native was released which gave developers a single platform for development on the web, iOS and android.</p>
                    <p>The result is a huge boost in productivity allowing us to offer mobile apps for a fraction of the previous cost.</p>
                </div>
                <div class="flex-row-right flex-row-image" style={`background-color:  white`}>
                    <h2>Trend for React Native</h2>
                    {trendGraph}
                    <p>2015 - 2018</p>
                    <p>Source: Google Trends</p>
                </div>
            </div>

            <div class="flex-row">
                <div class="flex-row-left flex-row-content" style={`background-color: ${themeColor[2]}; color: white`}>
                    <h2>Let's Talk</h2>
                    <p>Contact us to discuss your requirements without obligation.</p>
                </div>
                <div class="flex-row-right flex-row-image" style={`background-color:  white`}>
                    {reactIcon}
                    <h2>React Developer since 2015</h2>
                </div>
            </div>

        </div>
    </render>

    // const el = document.getElementById("app-store-logo") as HTMLElement
    // patch(el, () => appStoreIcon(pco, 2000))
}

export {
    mobileAppPageContent
};

