import {
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import { sitePix, linkClick } from '@appzuka/rxnano';
import { assetBase } from 'site/siteContent';

import './portfolioList.scss';

const portfolioPix = (id, mediaSizes?) => {
    sitePix(assetBase, `portfolio/${id}`);
};

const portfolioSquare = (id, title, desc) => {

    elementOpen('a', `portfolio-link-${id}`, null, 'href', `\/portfolio\/${id}`,
    'class', `portfolio-square portfolio-square-${id}`,
    'onclick', (event) => {
        linkClick(event, `/portfolio/${id}`);
    });

        elementOpen('div', `portfolio-pad-${id}`, null, 'class', 'image-padding');
        portfolioPix(id);
        elementClose('div');
        elementOpen('h2');
            text(title);
        elementClose('h2');
        elementOpen('p');
            text(desc);
        elementClose('p');
    elementClose('a');

};

const portfolioList = (pco, except?: string) => {

    let exclude = except;
    const m = window.location.pathname.match(/^\/portfolio\/(.*)$/);
    if (m && (m.length > 1)) {
        exclude = m[1];
    }

    elementOpen('div', `portfolio-list}`, null, 'class', 'portfolio-list');
        const list = [
            ['procom', 'Procom', 'Reactive Website'],
            ['panodrone', 'Panodrone', 'Website with video'],
            ['dharma', 'Recovery Dharma', 'News Website'],
            ['harkstead', 'Harkstead Village', 'Community Website'],
            ['farside', 'Far Side Films', 'Media Website'],
            ['fifthsense', 'FifthSense', 'e-commerce Website'],
            ['meadones', 'Meadon Editorial Services', 'Business Website'],
            ['alexander', 'Dean Alexander Conditioning', 'Personal Trainer Website']
        ];
        list.filter(p => p[0] !== exclude).map(p => { portfolioSquare(p[0], p[1], p[2]) });

    elementClose('div');
};

export {
    portfolioList
}
