// eslint-disable-next-line no-unused-vars
import { modulePage, PageContent } from '@appzuka/rxnano';

import { portfolioList } from './portfolioList';

import { pageSpeedResults } from 'site/components/lighthouseResults/lighthouseResults';

// Common to all portfolio pages
import './portfolioContent.scss';

// eslint-disable-next-line no-unused-vars
import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

// const aboutLoader = () => {
//     return import(
//       /* webpackMode: "lazy",
//          webpackChunkName: "aboutcontent" */
//       'site/pages/about/aboutContent.tsx');
// };

const procomLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-procom" */ './procomContent');
};

const panodroneLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-panodrone" */ './panodroneContent');
};

const fifthsenseLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-fifthsense" */ './fifthsenseContent');
};

const dharmaLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-dharma" */ './dharmaContent');
};

const farsideLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-farside" */ './farsideContent');
};

const harksteadLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-harkstead" */ './harksteadContent');
};

const meadonLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-meadon" */ './meadonContent');
};

const alexanderLoader = () => {
  return import(/* webpackMode: "lazy", webpackChunkName: "portfolio-alexander" */ './alexanderContent');
};

const portfolioPage = (pco) => {
  <render>
    <h2>Our Portfolio</h2>
    <p>Some examples of what we have achieved for our customers</p>
    <p>Select one of the projects below for further details:</p>
  </render>

  portfolioList(pco);
};

const portfolioContentPage = (pco, content) => {
  content(pco);
  <render>
    <h2>Our Portfolio</h2>
    <p>Some examples of what we have achieved for our customers</p>
    <p>Select one of the projects below for further details:</p>
  </render>

  // FIXME: except current page
  portfolioList(pco);
};

const portfolioHeader = (title, summary, desktop, mobile) =>
  <render>
    <div class='portfolio-top-header'>
      <div class='portfolio-title'>
        <h2>{title}</h2>
        <p>{summary}</p>
      </div>
      {() => pageSpeedResults(desktop, mobile)}
    </div>
  </render>;

const portfolioBannerTitle = (title, ...p): HTMLDivElement => {
  const addP = (el, text) => {
    let pel1 = document.createElement('p');
    pel1.textContent = text;
    el.appendChild(pel1);
  }

  let twrap = document.createElement('div') as HTMLDivElement;
  twrap.setAttribute('class', 'portfolio-banner-title');
  let hel = document.createElement('h2');
  let tel = document.createElement('text');
  hel.textContent = title;
  twrap.appendChild(hel);
  p.map(l => { addP(twrap, l); })
  return twrap;
}

const portfolioPages: PageContent = {
  content: [
    {
      pageLocation: 'portfolio',
      image: ['banners/portfolio', 'Our Portfolio'],
      pageTitle: 'Our Portfolio',
      content: (pco) => portfolioPage(pco),
    },
    {
      pageLocation: 'portfolio/procom',
      image: ['banners/procom-portfolio', portfolioBannerTitle('Our Portfolio - Procom', 'Responsive Website', 'Serverless Client-Side Application')],
      pageTitle: 'Our Portfolio - Procom',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'procom', 'portfolio-procom', procomLoader)),
      name: 'procom'
    },
    {
      pageLocation: 'portfolio/panodrone',
      image: ['banners/portfolio', 'Our Portfolio - Panodrone'],
      pageTitle: 'Our Portfolio - Panodrone',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'panodrone', 'portfolio-panodrone', panodroneLoader)),
      name: 'panodrone'
    },
    {
      pageLocation: 'portfolio/fifthsense',
      image: ['banners/portfolio', 'Our Portfolio - FifthSense'],
      pageTitle: 'Our Portfolio - Fifthsense',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'fifthsense', 'portfolio-fifthsense', fifthsenseLoader)),
      name: 'fifthsense'
    },
    {
      pageLocation: 'portfolio/dharma',
      image: ['banners/portfolio', 'Our Portfolio - Dharma Recovery'],
      pageTitle: 'Our Portfolio - Dharma Recovery',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'dharma', 'portfolio-dharma', dharmaLoader)),
      name: 'dharma'
    },
    {
      pageLocation: 'portfolio/farside',
      image: ['banners/portfolio', 'Our Portfolio - Far Side Films'],
      pageTitle: 'Our Portfolio - Far Side Films',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'farside', 'portfolio-farside', farsideLoader)),
      name: 'farside'
    },
    {
      pageLocation: 'portfolio/harkstead',
      image: ['banners/portfolio', 'Our Portfolio - Harkstead Village'],
      pageTitle: 'Our Portfolio - Harkstead',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'harkstead', 'portfolio-harkstead', harksteadLoader)),
      name: 'harkstead'
    },
    {
      pageLocation: 'portfolio/meadones',
      image: ['banners/portfolio', 'Our Portfolio - Meadon Editorial Services'],
      pageTitle: 'Our Portfolio - Meadon Editorial Services',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'meadon', 'portfolio-meadon', meadonLoader)),
      name: 'meadon'
    },
    {
      pageLocation: 'portfolio/alexander',
      image: ['banners/portfolio', 'Our Portfolio - Dean Alexander Conditioning'],
      pageTitle: 'Our Portfolio - Dean Alexander Conditioning',
      content: (pco) => portfolioContentPage(pco, () => modulePage(pco, 'alexander', 'portfolio-alexander', alexanderLoader)),
      name: 'alexander'
    }

  ],
  // TODO: Future, Interests
  subMenu: [
    { label: 'portfolio', link: '/portfolio' },
    { label: 'procom', link: '/portfolio/procom' },
    { label: 'panodrone', link: '/portfolio/panodrone' },
    { label: 'fifthsense', link: '/portfolio/fifthsense' },
    { label: 'dharma', link: '/portfolio/dharma' },
    { label: 'farside', link: '/portfolio/farside' },
    { label: 'harkstead', link: '/portfolio/harkstead' },
    { label: 'meadon-es', link: '/portfolio/meadones' }
  ],
  subMenuClass: 'portfolio'
}

export {
  portfolioPages,
  portfolioHeader
};

