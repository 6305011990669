import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';


import { BehaviorSubject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import { messageContent, authContent } from './index';
import { GetCognitoUser } from './authImports';

import './loginform.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';

const formData = new BehaviorSubject({old: '', password: '', verify: ''});

const oldSubject = new BehaviorSubject(false);
const passwordSubject = new BehaviorSubject(false);
const verifySubject = new BehaviorSubject(false);

const submitObserver = combineLatest(oldSubject, passwordSubject, verifySubject).pipe(
    // tap(v => console.log(v))
);

const submitInput = (pageChangeObservable, options) => {

    const el = elementOpen('input', null, ['type', 'submit', 'value', 'Submit']);
    elementClose('input');

    submitObserver
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(v => {
        if (v.reduce((a,c) => a && c, true)) {
        // if (true) {
            el.removeAttribute('disabled');
            el.style.backgroundColor=options.highlight;
        } else {
            el.setAttribute('disabled', 'disabled')
            el.style.backgroundColor='';
        }
    });
}

const passwordInput = (pageChangeObservable, subject, label, dataKey, options) => {

    const errorMsg = (dataKey === 'verify')?'New password and verification are not the same':'Password must be valid';

    elementOpen('div', null, ['class', 'input-element']);
        const el = elementVoid('input', `${dataKey}-input`, ['id', `${dataKey}passwordinput`, 'type', 'password', 'autofocus', (dataKey === 'old')]) as HTMLInputElement;
        const labelel = elementOpen('p', `${dataKey}-label`, ['class', 'input-label', 'id', `${dataKey}-label`]);
            text(label)
            elementOpen('span', null);
                text(errorMsg);
            elementClose('span');
        elementClose('p');
    elementClose('div');

    el.style.borderBottomColor=options.highlight;

    const source = fromEvent(el, 'input');
    const inputBlur = fromEvent(el, 'blur');

    const validInput = () => {
        if (dataKey === 'verify')
            return el.value === formData.getValue().password;
        else if (dataKey === 'password') {
            if (formData.getValue().verify.length > 0) {
                const velpi = document.getElementById('verifypasswordinput') as HTMLElement;
                const vel = document.getElementById('verify-label') as HTMLElement;
                if (formData.getValue().verify !== el.value) {
                    console.log('no equal')
                    velpi.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                    vel.classList.add('input-invalid');
                    return false;
                } else {
                    velpi.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
                    vel.classList.remove('input-invalid');
                    return el.value.length > 6;
                }
            } else {
                return el.value.length > 6;
            }
        }
        else
            return el.value.length > 6;
    }

    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (el.value.length > 0) {
                labelel.classList.add("input-non-empty");
            } else {
                labelel.classList.remove("input-non-empty");
            }
        }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el.style.backgroundColor = '';
                subject.next(true);
                labelel.classList.remove('input-invalid');
            } else {
                el.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                subject.next(false);
                labelel.classList.add('input-invalid');
            }
        }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
            el.value = data[dataKey];
            if (data[dataKey].length > 0) {
                labelel.classList.add("input-non-empty");
            } else {
                labelel.classList.remove("input-non-empty");
            }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el.style.backgroundColor = '';
                labelel.classList.remove('input-invalid');
                subject.next(true);
            } else {
                subject.next(false); 
            }
            formData.next({...formData.value, [dataKey]: el.value})
        }
    );
}

const submitForm = async (pco, e, options) => {
    e.preventDefault();

    const cognitoUser = await GetCognitoUser() as unknown as CognitoUser;
    cognitoUser.getSession((e,session) => {
        if (e) {
            console.log(e)
        } else {
            cognitoUser.changePassword(formData.getValue().old, formData.getValue().password, function(err, result) {
                if (err) {
                    console.log('cpw error: ' + err.message);
                    return;
                }
                console.log('call result: ' + result);
                messageContent.next('Change Password Successful');
                authContent.next({action: 'account'})
            });
        }

    });


    // const session = authData.getValue();

    // // const session = cognitoUser.getSignInUserSession();
    // console.log(session)
    // GetCredentials(session.idToken)
    //     .then(d => {
    //         console.log('getcred ok')
    //         console.log(d)

    //     })
    //     .catch(e => {
    //         console.log('getcred error')
    //     });


}

const changePasswordForm = async (pco, options) => {

    // const cognitoUser = await GetCognitoUser() as unknown as CognitoUser;

    // cognitoUser.getSession((e,session) => {
    //     if (e) {
    //         console.log(e)
    //     } else {
    //         cognitoUser.getUserAttributes(function(err, result) {
    //             if (err) {
    //                 alert(err.message || JSON.stringify(err));
    //                 return;
    //             }
    //             for (let i = 0; i < result!.length; i++) {
    //                 console.log(
    //                     'attribute ' + result![i].getName() + ' has value ' + result![i].getValue()
    //                 );
    //             }
    //         });
    //     }
    // });



    <render>
        <div id="changepassword">
            <h2>Change Password</h2>

        </div>
        <div class="login-form-wrapper">
            <form onsubmit={(e) => submitForm(pco, e, options)}>
                {() => passwordInput(pco, oldSubject, 'Existing Password', 'old', options)}
                {() => passwordInput(pco, passwordSubject, 'New Password', 'password', options)}
                {() => passwordInput(pco, verifySubject, 'Verify Password', 'verify', options)}
                <div class="submit-area">
                    {() => submitInput(pco, options)}
                    <input type="button" onclick={(e) => authContent.next({action: 'account'})} value="Cancel"></input>
                </div>

            </form>
        </div>
    </render>

setTimeout(() => {
    const ddel = document.getElementById('oldpasswordinput');
    console.log(ddel)
}, 5000);

}

export {
    changePasswordForm
};

