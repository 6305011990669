import {
    elementOpen,
    elementClose,
    skip
} from 'incremental-dom';

import { modulePage } from '@appzuka/rxnano';

const webImageLoader = () => {
    return import(/* webpackMode: "lazy", webpackChunkName: "webimages" */ './webImageContent');
};

// TODO: Make this load reduced images first on a slow network.
// TODO: Better to split the files into separate modules?

const info = (pco, delay = 0) =>
        modulePage(pco, 'info', 'webdesignimages', webImageLoader, {loaderOptions: {delay: delay}});

const iceberg = (pco, delay = 0) =>
        modulePage(pco, 'iceberg', 'webdesignimages', webImageLoader, {loaderOptions: {delay: delay}})

const fastpc = (pco, delay = 0) =>
        modulePage(pco, 'fastpc', 'webdesignimages', webImageLoader, {loaderOptions: {delay: delay}})

const brain = (pco, delay = 0) =>
        modulePage(pco, 'brain', 'webdesignimages', webImageLoader, {loaderOptions: {delay: delay}})

const blocks = (pco, delay = 0) =>
        modulePage(pco, 'blocks', 'webdesignimages', webImageLoader, {loaderOptions: {delay: delay}})

export {
    info,
    iceberg,
    fastpc,
    brain,
    blocks
};
