import {
    elementOpen,
    elementClose,
    text,
    patch
} from 'incremental-dom';

// import { jwtDecode } from 'jwt-decode';
import { PageContent, linkClick } from '@appzuka/rxnano';

// import * as DynamoDB from "aws-sdk/clients/dynamodb";
import { AuthData, GetCognitoUser, GetCredentials } from 'site/pages/account/authImports';


import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

// import 'site/site.scss';

let DBImport = false;

const ImportAuth = () => {
    // if (!DBImport) console.log(`importing dynamodb bundle`);

    return import(
        /* webpackMode: "lazy",
        webpackChunkName: "ddb" */
        'site/pages/account/dynamodb').then(async m => {
            if (!DBImport) {
                // await m.RestoreUser();
                DBImport = true;
            }
            return m;
        });
};

const GetErrorLog = (creds, days?) => ImportAuth().then(m => m.GetErrorLog(creds, days));

const errorlogContent = async (pco) => {
    <render>
        <h2 class="errorlog-wrapper">
            Error Log
            <input type='submit'  onclick={(e)=>linkClick(e, '/account')} value="Account"/>
            <div id='login-status'></div>
        </h2>
    </render>

    const loginData = await (await AuthData()).getValue();
    // console.log(loginData)
    const el = document.getElementById('login-status') as HTMLDivElement;
    if (loginData.idToken !== undefined) {

        const cognitoUser = await GetCognitoUser();
        cognitoUser!.getSession((e,session) => {
            if (e) {
                console.log(e)
            } else {
                GetCredentials(session.idToken).then((creds) => {

                    const groups = session.idToken.payload['cognito:groups'] || [];
                    const permitted = groups.includes('admin');
                    let role='';
                    cognitoUser!.getUserAttributes(function(err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                        // for (let i = 0; i < result!.length; i++) {
                        //     if (result![i].getName() === 'custom:role') {
                        //         role = result![i].getValue();
                        //     }
                        //     console.log(
                        //         'attribute ' + result![i].getName() + ' has value ' + result![i].getValue()
                        //     );
                        // }
                        // console.log('Role: '+role);
                    });

                    if (permitted) {
                        GetErrorLog(creds, 60).then(items => {
                            patch(el, () => {
                                <render>
                                    {() => items.map(elog => {
                                        return(
                                            <render><p>{elog.site} {elog.date} {elog.message} </p></render>
                                        )
                                    })}
                                </render>
                            });
                        })
                        .catch(err => {
                            console.log("ddb error");
                            console.log(err);
                        })
                    } else {
                        patch(el, () => {
                            <render>
                                <p>You do not have permission to view this page.</p>
                            </render>
                        });
                    }
                })
                .catch( e => {console.log(e)})
            }
        });
    } else {
        patch(el, () => {
            <render>
                <p>You must be logged in to view this page.</p>
            </render>
        });
    };
}

const errorlogPages : PageContent = {
    content: [
        {
            pageLocation: 'account/errorlog',
            image: ['banners/portfolio', 'Error Log'],
            pageTitle: 'errorlog',
            index: false,
            content: errorlogContent
        }
    ],
    subMenu: []
};

export {
    errorlogPages
};

