import {
  elementOpen,
  elementClose,
  text,
  patch
} from 'incremental-dom';

import rocketSVGRaw from './rocketFull.svg';

import { serviceBlocks } from 'site/components/serviceBlocks/serviceBlocks'
import { svgBanner } from 'site/components/headingBanner/banners'
import { PageContent, completeCallback, rxnanoComponent, linkClick, render } from '@appzuka/rxnano';
import { skillsContent as skillsLogos } from 'site/components/skills/skills';

import { portfolioCarouselContent } from 'site/pages/portfolio/portfolioCarousel';

import { lightHouseResults } from 'site/components/lighthouseResults/lighthouseResults';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import 'site/site.scss';
import './home.scss';

const rocketSVG = () => { return rocketSVGRaw('rocket-svg') };;

const servicesBanner = (pco) => {
  svgBanner(() => serviceBlocks(pco), 'Services', 'We can manage your web or mobile project or use our skills to help you with yours');
}

const facts = [
  '53% of mobile site visits are abandoned if pages take longer than 3 seconds to load',
  'From July 2018 Google will use mobile page speed to rank sites',
  'The average user device costs less than 200 USD',
  '19 seconds is the average time a mobile web page takes to load on a 3G connection',
  'The BBC has seen a loss of 10% of their users for every extra second of page load'
];

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const threeFacts = () => {
  shuffleArray(facts);
  facts.slice(2).map(f => {
    elementOpen('h2');
    text(f)
    elementClose('h2');
  })
}

const homeBanner = () => {
  render(
    <div class="rocket-banner">
      <div class="banner-heading">
        <h2>appZuka hand code the fastest websites on the planet</h2>
        <div class="banner-heading-bullets">
          {threeFacts}
        </div>
      </div>
      <div id="rocket-wrapper">
        {rocketSVG}
      </div>
    </div>
  );
}

const lighthouseLink = () => {
  elementOpen('p');
  elementOpen('a', null, null, 'class', 'lighthouse-link', 'href', '/performance/lighthouse',
    'onclick', (event) => {
      linkClick(event, '/performance/lighthouse');
    });

  text('How does your site perform?  Click here to get the lighthouse score for your site.');

  elementClose('a');
  elementClose('p');
}

// FIXME: Link on third blockx
const lighthousePerf = () => {
  <render>
    <div>
      <h2 class="home-mission lighthouse-mission">
        Outstanding LightHouse Performance Scores
            </h2>
      <div class="portfolio-tech-icons-reverse lighthouse-gauges">
        {() => lightHouseResults(99, 100, 100, 100, 58, false)}
      </div>
      <div class="three-block">
        <p>Lighthouse measures how your website performs on real-world devices and connections.</p>
        <p>A low score indicates that your site loads slowly, resulting in abandoned visits.</p>
        {lighthouseLink}

      </div>
    </div>
  </render>
}

const homeContent = (pco) => {
  let serviceBlocksEl: HTMLElement;
  let skillsLogosEl: HTMLElement;
  <render>
    {lighthousePerf}
    <div ref={e => serviceBlocksEl = e}></div>
    <h2 class="home-mission lighthouse-mission" id='portfolio'>
      Our Portfolio
    </h2>
    {() => rxnanoComponent.carousel(pco, portfolioCarouselContent)}
    <div ref={e => skillsLogosEl = e}></div>
  </render>

  // FIXME: Cancel if page changed
  patch(serviceBlocksEl!, () => serviceBlocks(pco, 2000));

  // Only load skills once the page is scrolled
  let scrollThrottle = undefined as undefined | number;
  const isNil = (v: any): boolean => ((v === null) || (v === undefined));
  window.addEventListener('scroll', (e) => {
    // Trigger scroll events only after the end of scrolling
    if (!isNil(scrollThrottle)) {
      clearTimeout(scrollThrottle);
    }

    scrollThrottle = setTimeout((() => {
      scrollThrottle = undefined;
      patch(skillsLogosEl!, () => skillsLogos(pco, 100));
      // FIXME: Pass stage to callback
      completeCallback('home');
    }) as TimerHandler, 200);
  });

  // FIXME: Pass stage to callback
  completeCallback('home');
}

const wildpage = () => {
  // TODO: library should find matches and pass to content
  
  const matches = window.location.pathname.match(/wild\/(.*)/);
  console.log(matches)
  render(<div>wildcard {matches![1]}</div>)

}

const wild2 = (pco, variables) => {
  // TODO: library should find matches and pass to content
  
  render(<div>wildcard2 {variables.id} {variables.sector}</div>)

}

const wildbase = (pco, variables) => {
  // TODO: library should find matches and pass to content
  
  render(<div>wildcard2 base {variables.id} {variables.sector}</div>)

}

const homePages: PageContent = {
  content: [
    {
      pageLocation: '',
      image: homeBanner,
      pageTitle: 'Home',
      content: homeContent
    },
    {
      pageLocation: /wild\/(.*)/,
      image: homeBanner,
      pageTitle: 'Wild',
      content: wildpage
    },
    {
      pageLocation: 'wild2/:id/blah/:sector',
      image: homeBanner,
      pageTitle: 'Wild2',
      content: wild2
    },
    {
      pageLocation: 'wild2/:id/blah',
      image: homeBanner,
      pageTitle: 'Wild2',
      content: wildbase
    }
  ],
  subMenu: []
};

export {
  homePages
};

