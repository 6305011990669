import {
    elementOpen,
    elementClose,
    elementVoid,
    text,
    patch
} from 'incremental-dom';


import { BehaviorSubject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import { messageContent, authContent } from './index';
import { RestoreUser, GetCognitoUser } from './authImports';

import './loginform.scss';
import { CognitoUser } from 'amazon-cognito-identity-js';

const formData = new BehaviorSubject({password: '', code: ''});

const passwordSubject = new BehaviorSubject(false);
const codeSubject = new BehaviorSubject(false);

const submitObserver = combineLatest(passwordSubject, codeSubject).pipe(
    // tap(v => console.log(v))
);

const submitInput = (pageChangeObservable, options) => {

    const el = elementOpen('input', null, ['type', 'submit', 'value', 'Submit']);
    elementClose('input');

    submitObserver
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(v => {
        if (v.reduce((a,c) => a && c, true)) {
        // if (true) {
            el.removeAttribute('disabled');
            el.style.backgroundColor=options.highlight;
        } else {
            el.setAttribute('disabled', 'disabled')
            el.style.backgroundColor='';
        }
    });
}

const codeInput = (pageChangeObservable, label, options) => {

    elementOpen('div', null, ['class', 'input-element']);
        const el2 = elementVoid('input', 'code-input', ['type', 'text']) as HTMLInputElement;
        const labelel2 = elementOpen('p', 'code-label', ['class', 'input-label']);
            text(label)
            elementOpen('span', null);
                text('Code must be at least 6 characters long');
            elementClose('span');
        elementClose('p');

    elementClose('div');

    el2.style.borderBottomColor=options.highlight;

    const source = fromEvent(el2, 'input');
    const inputBlur = fromEvent(el2, 'blur');

    const validInput = () => el2.value.length > 5

    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (el2.value.length > 0) {
                labelel2.classList.add("input-non-empty");
            } else {
                labelel2.classList.remove("input-non-empty");
            }
        }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                codeSubject.next(true);
                labelel2.classList.remove('input-invalid');
            } else {
                el2.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                codeSubject.next(false);
                labelel2.classList.add('input-invalid');
            }
        }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
                el2.value = data.code;
                if (data.code.length > 0) {
                    labelel2.classList.add("input-non-empty");
                } else {
                    labelel2.classList.remove("input-non-empty");
                }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                labelel2.classList.remove('input-invalid');
                codeSubject.next(true);
            } else {
                codeSubject.next(false); 
            }
            formData.next({...formData.value, code: el2.value})
        }
    );
}

const passwordInput = (pageChangeObservable, label, options) => {

    elementOpen('div', null, ['class', 'input-element']);
        const el2 = elementVoid('input', 'password-input', ['type', 'password']) as HTMLInputElement;
        const labelel2 = elementOpen('p', 'password-label', ['class', 'input-label']);
            text(label)
            elementOpen('span', null);
                text('Password must be at least 6 characters long');
            elementClose('span');
        elementClose('p');

    elementClose('div');

    el2.style.borderBottomColor=options.highlight;

    const source = fromEvent(el2, 'input');
    const inputBlur = fromEvent(el2, 'blur');

    const validInput = () => el2.value.length > 5

    // NICE: better to use pagechangeobservable to unsubscribe when the page changes
    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (el2.value.length > 0) {
                labelel2.classList.add("input-non-empty");
            } else {
                labelel2.classList.remove("input-non-empty");
            }
        }
    );

    inputBlur
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                passwordSubject.next(true);
                labelel2.classList.remove('input-invalid');
            } else {
                el2.style.backgroundColor = 'rgba(255, 100, 100, 0.2)';
                passwordSubject.next(false);
                labelel2.classList.add('input-invalid');
            }
        }
    );

    formData
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        (data) => {
                el2.value = data.password;
                if (data.password.length > 0) {
                    labelel2.classList.add("input-non-empty");
                } else {
                    labelel2.classList.remove("input-non-empty");
                }
        }
    );

    source
    .pipe(
        takeUntil(pageChangeObservable), // Cancel if the page is changed
    )
    .subscribe(
        () => {
            if (validInput()) {
                el2.style.backgroundColor = '';
                labelel2.classList.remove('input-invalid');
                passwordSubject.next(true);
            } else {
                passwordSubject.next(false); 
            }
            formData.next({...formData.value, password: el2.value})
        }
    );
}

const sendCode = async (e, email) => {
    e.preventDefault();

    const cognitoUser = await GetCognitoUser('web@appzuka.com') as CognitoUser;

    cognitoUser.forgotPassword({
        onSuccess: function(data) {
            // successfully initiated reset password request
            console.log('CodeDeliveryData from forgotPassword: ');
            console.log(data);
            messageContent.next('Verification code sent')
        },
        onFailure: function(err) {
            alert(err.message || JSON.stringify(err));
        },
        //Optional automatic callback
        // inputVerificationCode: function(data) {
        //     console.log('Code sent to: ' + data);
        //     var code = document.getElementById('code').value;
        //     var newPassword = document.getElementById('new_password').value;
        //     cognitoUser.confirmPassword(verificationCode, newPassword, {
        //         onSuccess() {
        //             console.log('Password confirmed!');
        //         },
        //         onFailure(err) {
        //             console.log('Password not confirmed!');
        //         },
        //     });
        // },
    });
}

const submitForm = async (e, options) => {
    e.preventDefault();

    const cognitoUser = await GetCognitoUser('web@appzuka.com') as CognitoUser;

    const {code, password } = formData.getValue();
    cognitoUser.confirmPassword(code, password, {
        onSuccess() {
            console.log('Password changed!');
            messageContent.next('Password reset successful. Please log in using new password.');
            authContent.next({action: 'account'});
        },
        onFailure(err) {
            console.log('Password change failed!');
        },
    });
}

const forgotPasswordForm = async (pco, options, email) => {

    <render>
        <div class="login-form-wrapper">
            <form onsubmit={(e) => submitForm(e, options)}>
                <p>Forgot Password for user: {email}</p>
                <input type="button" onclick={(e) => {sendCode(e, email)}} value="Send Code"></input>
                {() => codeInput(pco, 'code', options)}
                {() => passwordInput(pco, 'password', options)}
                <div class="submit-area">
                    {() => submitInput(pco, options)}
                    <input type="button" onclick={(e) => authContent.next({action: 'account'})} value="Cancel"></input>
                </div>

            </form>
        </div>
    </render>
}

export {
    forgotPasswordForm
};

